/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_nav .row-nav-social-media {
  padding-bottom: 10px;
}

.row-nav-social-media {
  position: relative;
  z-index: 10;
  float: left;
  width: 100%;
  padding-top: 10px;

  a svg {
    width: 16px;
    height: 16px;
    margin: 0 0 5px;
  }
}

