.main-content-sign-in {
  margin-bottom: 5vw;
}
/* PROFILES
- - - - - - - - - - - - - - - - - - - - - - - - - - */
label.label-agree span {
  font-size: 11px;
  line-height: 15px;
  opacity: .9;
}

label.label-agree .checkbox:checked + span:before,
label.label-agree .checkbox + span:before {
  background-color: #707070;
}

label.label-agree .checkbox + span {
  padding-left: 25px;
}

label.label-agree .checkbox + span:before {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
}

.tabs-toggles-accordion {
  margin-top: -10px;

  .tab-container:not(:last-child) {
    .tab-inner-active {
      padding-bottom: 20px;
    }
  }
}
