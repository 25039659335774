/* GLOBAL
- - - - - - - - - - - - - - - - - - - - - - - - - - */
html {
  font-family: $primary_font;
  font-weight: 300;
  font-size: 14px;
  line-height: 1em;
}

h1, h2, h3,
h4, h5, h6,
.h0, .h1, .h2, .h3,
.h4, .h4-big, .h5, .h6,
p, .p,
ul, ol, li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
}

h1, h2, h3,
h4, h5, h6,
.h0, .h1, .h2, .h3,
.h4, .h4-big, .h5, .h6,
p, .p,
ul li,
.hr,
div.table:not(.row) {
  margin-bottom: $grid_gap * 2;
}

.hr {
  margin-top: $grid_gap * 2;
}

h1, h2, h3,
h4, h5, h6,
.h0, .h1, .h2, .h3,
.h4, .h4-big, .h5, .h6,
p, .p {
  a, small {
    display: inline-block;
  }

  a {
    color: inherit;
  }

  small {
    font-size: inherit;
    line-height: inherit;

    &.baseline {
      vertical-align: baseline;
      font-size: .50em;
      line-height: 1em;
    }
  }
}

.h0 {
  font-size: 48px;
  line-height: 1em;
}

h1, .h1 {
  font-size: 27px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-weight: 500;
}

h2, .h2 {
  font-size: 20px;
  line-height: 1.3em;
}

h3, .h3 {
  font-size: 18px;
  line-height: 1.3em;
}

h4, .h4 {
  font-size: 16px;
  line-height: 1.4em;
}

.h4-big {
  font-size: 17px;
  line-height: 1.4em;
}

h5, .h5 {
  font-size: 14px;
  line-height: 1.4em;
}

h6, .h6 {
  font-size: 12px;
  line-height: 1.4em;
}

.h1-small-extra {
  margin-right: 15px;
}

p, .p, li {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

ul {
  list-style-type: none;
}

ul.lower-alpha,
ul.upper-roman,
ul.decimal,
ul.side {
  padding-left: 40px;

  li {
    display: list-item;
  }
}

ul.lower-alpha {
  list-style-type: lower-alpha;
}

ul.upper-roman {
  list-style-type: upper-roman;
}

ul.decimal {
  list-style-type: decimal;
}

b, strong {
  font-weight: 600;
}

.letter_-0-5 {
  letter-spacing: -0.5px;
}

.letter_-1 {
  letter-spacing: -1px;
}

.letter_-1-5 {
  letter-spacing: -1.5px;
}

.letter_-2 {
  letter-spacing: -2px;
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.weight-bold {
  font-weight: bold;
}

.unset-height {
  height: unset !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.hr {
  display: block;
  height: 1px;
  padding: 0;
}

.centered {
  display: block;
  margin: 0 auto;
}

img.txt-png {
  width: auto;
  vertical-align: middle;

  &.png-custom-a4h {
    max-height: 15px;
  }

  &.png-custom-mid {
    max-height: 18px;
  }

  &.png-custom {
    max-height: 17px;
    opacity: .6;
  }
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.p-left {
  padding-left: $grid_gap * 4;
}

.p-right {
  padding-right: $grid_gap * 4;
}

.txt-center {
  text-align: center;
}

.txt-middle {
  vertical-align: middle;
}

.txt-inline {
  display: inline-block;
  vertical-align: inherit;
  width: auto;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.transform-none {
  text-transform: none;
}

.capitalize-first::first-letter,
.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.no-space {
  margin: 0 !important;
  padding: 0 !important;
}

.v-space {
  margin-bottom: -10px !important;
}

.nowrap {
  white-space: nowrap;
}

.break {
  word-break: break-word;
}

div.table:not(.row) {
  display: inline-block;
  width: 100%;
  overflow: auto;
}

table {
  border-collapse: collapse;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 480px !important;
  margin: 0;
  padding: 0;

  a {
    word-break: break-all;
  }

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }

  tr:nth-child(even) {
    background: rgba(255, 255, 255, .06)
  }

  tr:nth-child(odd) {
    background: rgba(255, 255, 255, .1)
  }

  td {
    //word-break: break-word;
    padding: 10px;
    vertical-align: top;
  }
}

// Read More-Less
[data-toggle-extended="true"] {
  max-height: unset !important;
}

.read-even-more {
  max-height: 63px;
  overflow: hidden;
  display: block;
  position: relative;
  color: rgba(255, 255, 255, .6);
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;

  &.invert-bottom {
    margin-bottom: -10px;
  }

  &.set-center {
    .toggle-text-controls {
      a {
        position: absolute;
        left: 50%;
        margin: 0 0 0 -100px;
        width: 200px;
        text-align: center;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    height: 19px;
    background: #000;
  }

  a {
    color: #fff;
  }
}

.toggle-text {
  .second {display: none;}
  + .split-more {display: none;}

  &.active {
    + .toggle-text-controls {display: initial;}
  }

  &.toggle-extended {
    .second {display: initial;}
  }
}

.toggle-text-controls {
  a {
    font-size: .8em;
    line-height: 19px;
    height: 19px;
    text-transform: capitalize;
    display: inline-block;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    color: #fff;
  }
}

.static-html {
  a {
    word-break: break-all;
  }
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  .read-even-more.show-all-on-desktop {
    max-height: unset !important;

    .toggle-text .second {
      display: inline;
    }

    .toggle-text-controls {
      display: none;
    }
  }
}

// RESPONSIVE - Tablet
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet} {
  .t-txt-left {
    text-align: left;
  }

  .t-txt-right {
    text-align: right;
  }

  /* GLOBAL
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .t-txt-center {
    text-align: center;
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .m-txt-left {
    text-align: left;
  }

  .m-txt-right {
    text-align: right;
  }

  .m-txt-center {
    text-align: center;
  }

  .p-left {
    padding-left: $grid_gap * 2;
  }

  .p-right {
    padding-right: $grid_gap * 2;
  }

  /* GLOBAL
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  h1, h2, h3,
  h4, h5, h6,
  .h0, .h1, .h2, .h3,
  .h4, .h4-big, .h5, .h6,
  p,
  ul li,
  .hr,
  div.table:not(.row) {
    margin-bottom: $grid_gap;
  }

  h1, h2, h3,
  h4, h5, h6,
  .h0, .h1, .h2, .h3,
  .h4, .h4-big, .h5, .h6,
  p, .p {
    small {
      &.baseline {
        font-size: .60em;
      }
    }
  }

  .h0 {
    font-size: 36px;
  }

  h1, .h1 {
    font-size: 17px;
    line-height: 1.6em;
  }

  h2, .h2 {
    font-size: 17px;
  }

  h3, .h3 {
    font-size: 15px;
  }

  h4, .h4 {
    font-size: 14px;
  }

  h5, .h5 {
    font-size: 14px;
  }

  h6, .h6 {
    font-size: 12px;
  }

  .v-space {
    margin-bottom: -5px !important;
  }

  .read-even-more {
    max-height: 57px;
    font-size: 14px;
    line-height: 19px;

    &.invert-bottom {
      margin-bottom: 0;
    }
  }
}
