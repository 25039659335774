/* DIR QUERIES
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$ltr: 'html[dir="ltr"]';
$rtl: 'html[dir="rtl"]';

/* LANG QUERIES
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$en: 'html[lang="en"]';
$it: 'html[lang="it"]';
$es: 'html[lang="es"]';

/* FONTS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
//$primary_font: 'Fira Sans', sans-serif;
$primary_font: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
$inputs_font: $primary_font;

/* GRID and LAYOUT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$total_col: 6;
$total_colspan: 6;
$total_row: 6;
$total_rowspan: 2;
$boxed_width: 1000px;
$boxed_small_width: 700px;
$boxed_medium_width: 1080px;
$boxed_large_width: 1400px;
$grid_gap: 10px;

/* HEADER - !important settings
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$desktop_market_height: 45px;
$tablet_market_height: 45px;
$mobile_market_height: 40px;
$mobile_small_market_height: 40px;

$desktop_header_height: 77px;
$tablet_header_height: 76px;
$mobile_header_height: 51px;
$mobile_small_header_height: 51px;

/* RESPONSIVE QUERIES
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$width_mobile: 375px;
$width_tablet: 768px;
$width_desktop: 1000px;
$width_desktop_large: 1200px;

$desktop_special: 'screen and (min-width:' + $boxed_large_width + ')';
$desktop: 'screen and (min-width:' + $width_desktop + ')';
$desktop_large: 'screen and (min-width:' + $width_desktop_large + ')';
$desktop_tablet: 'screen and (min-width:' + $width_tablet + ')';
$tablet: 'screen and (min-width:' + $width_tablet + ') and (max-width:' + ($width_desktop - 1) + ')';
$tablet_mobile: 'screen and (max-width:' + ($width_desktop - 1) + ')';
$mobile: 'screen and (max-width:' + ($width_tablet - 1) + ')';
$mobile_small: 'screen and (max-width:' + ($width_mobile - 1)+ ')';
