/* GLOBAL KEYFRAMES
- - - - - - - - - - - - - - - - - - - - - - - - - - */
// - Scale
@mixin animScaleFromTo($duration: 1s, $from: .5, $to: 1, $count: 1) {
  -webkit-animation: animScaleFromTo $duration linear 0s $count;
  -moz-animation: animScaleFromTo $duration linear 0s $count;
  animation: animScaleFromTo $duration linear 0s $count;

  @-webkit-keyframes animScaleFromTo {
    from {
      -webkit-transform: scale($from);
      transform: scale($from);
    }
    to {
      -webkit-transform: scale($to);
      transform: scale($to);
    }
  }

  @keyframes animScaleFromTo {
    from {
      -webkit-transform: scale($from);
      -moz-transform: scale($from);
      transform: scale($from);
    }
    to {
      -webkit-transform: scale($to);
      -moz-transform: scale($to);
      transform: scale($to);
    }
  }
}

// - Rotate
@mixin animRotating($duration: 6s, $from: 0deg, $to: 180deg, $count: 1) {
  -webkit-animation: animRotating $duration linear 0s $count;
  -moz-animation: animRotating $duration linear 0s $count;
  animation: animRotating $duration linear 0s $count;

  @-webkit-keyframes animRotating {
    from {
      -webkit-transform: rotate($from);
      transform: rotate($from);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes animRotating {
    from {
      -webkit-transform: rotate($from);
      -moz-transform: rotate($from);
      transform: rotate($from);
    }
    to {
      -webkit-transform: rotate($to);
      -moz-transform: rotate($to);
      transform: rotate($to);
    }
  }
}

// Background Color
@mixin animBackgroundColorFromTo($duration: 1s, $from: rgba(0, 0, 0, 0), $to: rgba(0, 0, 0, 1), $count: 1) {
  -webkit-animation: animBackgroundColorFromTo $duration linear 0s $count;
  -moz-animation: animBackgroundColorFromTo $duration linear 0s $count;
  animation: animBackgroundColorFromTo $duration linear 0s $count;

  @-webkit-keyframes animBackgroundColorFromTo {
    from {
      background: $from;
    }
    to {
      background: $to;
    }
  }

  @keyframes animBackgroundColorFromTo {
    from {
      background: $from;
    }
    to {
      background: $to;
    }
  }
}

// Opacity
@mixin animOpacityFromTo($duration: 1s, $from: 0, $to: 1, $delay: 0s, $count: 1) {
  -webkit-animation: animOpacityFromTo $duration linear $delay $count;
  -moz-animation: animOpacityFromTo $duration linear $delay $count;
  animation: animOpacityFromTo $duration linear $delay $count;

  @-webkit-keyframes animOpacityFromTo {
    from {
      opacity: $from;
    }
    to {
      opacity: $to;
    }
  }

  @keyframes animOpacityFromTo {
    from {
      opacity: $from;
    }
    to {
      opacity: $to;
    }
  }
}

@mixin transFadeTo($duration: 1s, $to: 1) {
  opacity: $to;
  transition: opacity $duration linear;
}
