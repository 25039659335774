/* START MODULE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.main-header [data-active-module="networks"] {
  .module-networks {
    display: block;
  }
}

// RESPONSIVE - Tablet and Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet_mobile} {
  .main-header [data-active-module="networks"] {
    .module-networks {
      visibility: visible;
      opacity: 1;
      z-index: 50;

      &.under-header {
        .section-full {
          left: 0;
        }
      }
    }
  }

  .module-networks {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    z-index: -999;

    &.under-header {
      .section-full {
        height: 100%;
        width: 85%;
        float: right;
        left: 85%;
        transition: left .2s ease-in-out;
      }
    }
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {

}




