/* CUSTOM CODE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
[v-cloak] {
  display: none !important;
}

.grecaptcha-badge {
  display: none;
}

.heading-with-dot {
  position: relative;
  color: #fff !important;
}

.heading-with-dot:after {
  background: #1aa81a;
  content: ' ';
  position: absolute;
  top: 0;
  left: auto;
  right: -12px;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  @include animScaleFromTo($duration: 1s, $from: .4, $to: 1, $count: infinite);
}
