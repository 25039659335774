/* START MODULE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header {
  position: relative;
  z-index: 60;
  min-width: 320px;

  .grid {
    @include data-grid_template($columns: auto auto 1fr auto auto);
  }
}

.header-nav-deals {
  float: left;

  .hnd-cta {
    float: left;
    height: $desktop_header_height;
    padding: 24px 15px 24px 0;
    cursor: pointer;
    text-decoration: none;
  }

  img {
    height: 29px;
    width: auto;
    transform: rotate(-6deg);
    animation: hndCtaAnim 4s infinite 2s;
  }
}

@keyframes hndCtaAnim {
  0% {
    transform: rotate(-6deg);
  }
  50% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(-6deg);
  }
}

/* LOGO
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_logo {
  svg.main-logo {
    width: 80px;
    height: 20px;
  }
}

/* USER MENU - Language, Search, Notifications, Account, Menu Hamburger
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_buttons {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  padding-top: 13px;

  .btn {
    float: left;
    padding: 6px 4px;
    font-size: 12px;
  }
  .btn.btn-one {
    border-color: #b8b8b8;
    color: #c5c5c5
  }
  .btn.btn-two {
    margin-left: 6px;
  }
}

.module-header_user {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;

  .flag {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 13px;
  }

  .box {
    position: relative;
    float: left;
    cursor: pointer;
    width: 25px;
    height: $desktop_header_height;
    color: inherit;

    + .box {
      margin-left: 10px;
    }

    // Not tab - Static Link
    a.link {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      width: inherit;
      color: inherit;
    }
  }

  // All Icons
  span.symbol {
    position: relative;
    top: 50%;
    left: 0;
    right: auto;
    bottom: auto;
    margin-top: -13px;
    display: block;
    width: inherit;
    height: 26px;
    line-height: 26px;
    text-align: center;

    i.icon,
    svg.icon {
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }

  // Notification Count
  span.badge {
    font-family: 'Helvetica', sans-serif;
    font-weight: normal;
    position: absolute;
    z-index: 5;
    top: -2px;
    left: auto;
    right: -4px;
    bottom: auto;
    min-width: 14px;
    height: 14px;
    font-size: 11px;
    line-height: 14px;
    border-radius: 2px;
    padding: 0;
  }

  // User Status
  span.dot {
    position: absolute;
    z-index: 5;
    top: auto;
    left: 50%;
    right: auto;
    bottom: -3px;
    margin-left: -4.5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1aa81a;
  }

  // WEBCAM dot
  span.webcam-dot {
    background: #1aa81a;
    content: ' ';
    position: absolute;
    top: 0;
    left: auto;
    right: -3px;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    @include animScaleFromTo($duration: 1s, $from: .4, $to: 1, $count: infinite);
  }

  // Navigation Arrow
  .box.box-navigation {
    i.icon.first,
    svg.icon.first {
      display: inline-block;
    }
    i.icon.second,
    svg.icon.second {
      display: none;
    }
  }

  .box.box-hearts {
    width: auto !important;

    .symbol {
      width: 24px;
    }

    .symbol .icon {
      transform: scale(1.2);
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.3);
  }
}

/* CALL to ACTION
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_cta {
  float: left;
  width: 100%;
  padding-left: 10px;
  min-width: 98px;

  .button-join {
    padding: 5px 10px;
    font-size: 13px;
  }
}

/* COMMON NAV
- - - - - - - - - - - - - - - - - - - - - - - - - - */
nav {
  .nav-items-icon {
    width: 30px;
    height: 23px;
    line-height: 23px;
    font-size: 20px;
    text-align: center;
    color: #fbca27;
  }

  .nav-items-header {
    color: #fff;
    font-size: 14px;
    padding: 10px 50px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
  }

  > ul > li > ul li .a.nav-items-all strong {
    color: #fbca27;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 1;
  }
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  /* NAVIGATION
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_nav {
    float: left;
  }

  nav {
    float: left;
    width: 100%;

    // Links
    .a {
      position: relative;
      float: left;
      width: 100%;
      white-space: nowrap;

      strong {
        position: relative;
        z-index: 5;
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        font-weight: 300;
        line-height: 1em;
        text-transform: uppercase;

        &.deals {
          border-radius: 3px;
          border: 2px solid #f2f580;
          color: #f2f580;
          padding: 5px 10px;
          font-weight: 500;
        }
      }

      i.icon,
      svg.icon {
        display: none;
      }
    }

    > ul > li > ul li.nav-items-header {
      white-space: nowrap;
      text-align: center;
    }

    > ul > li > ul li.nav-items-header:hover {
      background: none;
    }

    // More Less Icon
    span.more {
      position: absolute;
      z-index: -1;
      left: auto;
      right: 0;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 1em;

      i.icon,
      svg.icon {
        text-align: center;
        width: inherit;
        height: inherit;
        line-height: inherit;
        font-size: inherit;
      }
    }

    span.more + .a {
      padding-right: 20px;
    }

    // All Levels
    ul {
      float: left;
      margin: 0;

      li {
        float: left;
        margin: 0;
      }
    }

    // Level Categories
    > ul > li {
      position: relative;
      width: auto;
      margin-right: 15px;

      // Links
      > .a {
        height: $desktop_header_height;
        line-height: 70px;
      }
    }

    > ul > li:hover > ul {
      display: block;
    }

    // Level Sub-Categories
    > ul > li > ul {
      display: none;
      position: absolute;
      z-index: 10;
      top: 100%;
      bottom: auto;
      width: auto;
      left: 50%;
      right: auto;
      -webkit-transform: translateX(-54%);
      transform: translateX(-54%);

      // Links
      .a {
        min-width: 220px;
        padding: 15px;

          strong {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

.col.col-lang-de,
.col.col-lang-en,
.col.col-lang-es,
.col.col-lang-fr {display: block !important;}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  body.guest .module-header_user {
    min-width: 130px;
  }
  body.guest .module-header_user.-mhu-dynamic-width {
    min-width: unset;
  }
}

// RESPONSIVE - Tablet and Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet_mobile} {
  /* START MODULE
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .main-header [data-active-module="navigation"] {
    .module-header_nav {
      visibility: visible;
      opacity: 1;
      z-index: 50;
    }

    nav {
      right: 0;
    }

    .module-header_user {
      // Navigation Arrow
      .box.box-navigation {
        i.icon.first,
        svg.icon.first {
          display: none;
        }
        i.icon.second,
        svg.icon.second {
          display: inline-block;
        }
      }
    }
  }

  /* NAVIGATION
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_nav {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    z-index: -999;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: top .3s linear 0s;

    .close {
      position: absolute;
      z-index: 5;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: pointer;
    }
  }

  nav {
    position: relative;
    z-index: 10;
    width: 85%;
    float: left;
    right: 85%;
    transition: right .2s ease-in-out;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    // Links
    .a {
      position: relative;
      float: left;
      width: 100%;
      padding: 6px 45px 6px 0;
      padding-left: 20px;

      i.icon,
      svg.icon {
        float: left;
        width: 30px;
        height: 23px;
        line-height: 23px;
        font-size: 20px;
        text-align: center;
        color: #f2f580;
        margin-right: 10px;
        opacity: .6;
      }

      strong {
        position: relative;
        z-index: 5;
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        line-height: 1em;
        text-transform: uppercase;
      }

      &.a-colored-blue {
        color: #fff;
        background: #3faeea;

        strong {
          font-size: 19px;
        }

        .icon {
          color: #fff;
          fill: currentColor;
          opacity: 1;

          &.icon-renew {
            transform: rotate(45deg);
          }

          &.icon-upgrade {
            transform: rotate(-90deg);
          }
        }
      }
    }

    // More Less Icon
    span.more {
      position: absolute;
      z-index: 10;
      left: auto;
      right: 0;
      top: 0;
      width: 45px;
      height: 36px;
      font-size: 24px;
      line-height: 36px;
      padding-right: 20px;

      i.icon,
      svg.icon {
        float: right;
        width: 25px;
        height: inherit;
        line-height: inherit;
        font-size: inherit;
      }
    }

    span.more.more-full {
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
    }

    // All Levels
    ul {
      float: left;
      margin: 0;

      li {
        float: left;
        margin: 0;
      }
    }

    // Level Categories
    > ul > li {
      position: relative;
    }

    > ul > li.show-more {
      > ul {
        display: block;
      }

      > span.more i.icon,
      > span.more svg.icon {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
      }
    }

    // Level Sub-Categories
    > ul > li > ul {
      display: none;
      margin-bottom: 10px;

      .a {
        padding: 5px;
        padding-left: 60px;
      }

      li {
        width: 50%;
      }

      li .a strong {
        font-size: 14px;
      }
    }

    ul.nav-item-languages li {
      width: 100%;
      padding: 0 10px;
    }
  }
}

// RESPONSIVE - Tablet
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet} {
  /* USER MENU - Language, Search, Notifications, Account, Menu Hamburger
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_user .box {
      height: $tablet_header_height;
  }

  .module-header_user .box.box-navigation {
    margin-right: 20px;
  }

  /* NAVIGATION
- - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_nav {
    top: $tablet_header_height;
  }
  .main-header[data-header-top-bar="active"] {
    .module-header_nav {
      top: $tablet_header_height + $tablet_market_height;
    }
  }
  body.hide-marketplace-bar {
    .main-header[data-header-top-bar="active"] {
      .module-header_nav {
        top: $tablet_header_height;
      }
    }
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .header-nav-deals .hnd-cta {
    height: $mobile_header_height;
    padding: 10px 0 10px 5px;
  }

  /* LOGO
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_logo {
    svg.main-logo {
      width: 60px;
      height: 10px;
    }
  }

  /* NAVIGATION
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_nav {
    top: $mobile_header_height;

    .under-header {
      display: block;
      position: relative;
      padding: 0 !important;
      height: auto !important;

      &.module-search.hide {
        display: block !important;
      }
    }
  }
  .main-header[data-header-top-bar="active"] {
    .module-header_nav {
      top: $mobile_header_height + $mobile_market_height;
    }
  }
  body.hide-marketplace-bar {
    .main-header[data-header-top-bar="active"] {
      .module-header_nav {
        top: $mobile_header_height;
      }
    }
  }

  nav {
    padding-top: 0;
    padding-bottom: 0;

    // Links
    .a {
      padding-left: 10px;
    }

    // More Less Icon
    span.more {
      padding-right: 10px;
    }

    // Level Sub-Categories
    > ul > li > ul {
      .a {
        padding-left: 50px;
      }

      li {
        width: 100%;
      }
    }
  }

  /* USER MENU - Language, Search, Notifications, Account, Menu Hamburger
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_user span.symbol {
    margin-top: -15px;
    height: 30px;

    span.badge {
      top: 0;
    }

    span.dot {
      margin-left: -5px;
    }
  }

  .module-header_user .box {
    width: 30px;
    height: $mobile_header_height;
  }

  .module-header_user .box.box-navigation {
    margin-right: 10px;
  }

  .module-header_buttons {
    + .module-header_user {
      display: none;
    }
  }
}

// RESPONSIVE - Small Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile_small} {
  .header-nav-deals .hnd-cta {
    height: $mobile_small_header_height;
    padding: 10px 5px;
  }

  /* NAVIGATION
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_nav {
    top: $mobile_small_header_height;
  }
  .main-header[data-header-top-bar="active"] {
    .module-header_nav {
      top: $mobile_small_header_height + $mobile_small_market_height;
    }
  }
  body.hide-marketplace-bar {
    .main-header[data-header-top-bar="active"] {
      .module-header_nav {
        top: $mobile_small_header_height;
      }
    }
  }

  /* USER MENU - Language, Search, Notifications, Account, Menu Hamburger
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-header_user .box {
    height: $mobile_small_header_height;
  }

  .module-header_cta .button-join {
    padding: 5px;
    font-size: 11px;
  }
}

// RESPONSIVE - Custom
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
.header-nav-item_awarded_scenes {
  display: none;
}

@media (min-width: 1330px) {
  .header-dropdown-item_awarded-scenes {
    display: none;
  }

  .header-nav-item_awarded_scenes {
    display: inline-block;
  }
}

@media screen and (min-width:1000px) and (max-width:1199px) {
  html[lang="de"] {
    nav .a strong {letter-spacing: -.5px;}
    nav > ul > li {margin-right: 5px;}
  }
  html[lang="en"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 12px;}
  }
  html[lang="es"] {
    nav .a strong {letter-spacing: -.5px;}
    nav > ul > li {margin-right: 8px;}
  }
  html[lang="fr"] {
    nav .a strong {letter-spacing: -1px;}
    nav > ul > li {margin-right: 8px;}
  }
  html[lang="it"] {
    nav .a strong {letter-spacing: -.5px;}
    nav > ul > li {margin-right: 10px;}
  }
  html[lang="pt"] {
    nav .a strong {letter-spacing: -1px;}
    nav > ul > li {margin-right: 12px;}
  }
  html[lang="nl"] {
    nav .a strong {letter-spacing: -1.5px;}
    nav > ul > li {margin-right: 10px;}
  }
  html[lang="da"] {
    nav .a strong {letter-spacing: -1px;}
    nav > ul > li {margin-right: 8px;}
  }
  html[lang="fi"] {
    nav .a strong {letter-spacing: -.5px;}
    nav > ul > li {margin-right: 10px;}
  }
  html[lang="sv"] {
    nav .a strong {letter-spacing: -1.5px;}
    nav > ul > li {margin-right: 7px;}
  }
  html[lang="is"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 12px;}
  }
  html[lang="no"] {
    nav .a strong {letter-spacing: -.5px;}
    nav > ul > li {margin-right: 9px;}
  }
  html[lang="hi"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 20px;}
  }
  html[lang="ar"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-left: 15px;}
  }
  html[lang="ko"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 20px;}
  }
  html[lang="ru"] {
    nav .a strong {letter-spacing: -1.5px;}
    nav > ul > li {margin-right: 9px;}
  }
  html[lang="ja"] {
    nav .a strong {letter-spacing: -.5px;}
    nav > ul > li {margin-right: 5px;}
  }
  html[lang="zh"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 20px;}
  }
  html[lang="ro"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 15px;}
  }
  html[lang="el"] {
    nav .a strong {letter-spacing: -.5px;}
    nav > ul > li {margin-right: 9px;}
  }
  html[lang="hu"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 10px;}
  }
  html[lang="pl"] {
    nav .a strong {letter-spacing: -1px;}
    nav > ul > li {margin-right: 5px;}
  }
  html[lang="hr"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 10px;}
  }
  html[lang="id"] {
    nav .a strong {letter-spacing: 0;}
    nav > ul > li {margin-right: 10px;}
  }
}
