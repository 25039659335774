/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
// General
form {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 100%;
}

select::-ms-expand {
  display: none;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

::-ms-input-placeholder {
  opacity: 1;
}

:placeholder-shown {
  opacity: 1;
}

.inputs-boxes {
  position: relative;
  // z-index: 5;
  float: left;
  width: 100%;

  //&:hover {
  //  .inputs-modals {
  //    display: block;
  //  }
  //}
}

.input-select[data-roll] {
  white-space: nowrap;
}

.inputs-inline {
  display: inline-block;
  vertical-align: top;
  width: auto;
  float: none;
}

.search-form .button-close {
  background: transparent;
  border: none;
  color: gray;
  position: absolute;
  right: 40px;
  z-index: 20;
  height: 100%;
  cursor: pointer;
}

// Label
label.label {
  position: relative;
  width: 100%;
  float: left;

  span {
    float: left;
    position: relative;
    line-height: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: text;
  }

  span a {
    font-weight: 400;
    color: inherit;
  }

  span.req:after {
    content: '*';
    display: inline-block;
    vertical-align: top;
    margin: -2px 3px 0 3px;
    font-size: 13px;
    font-weight: 600;
  }

  &[for],
  &.label-for {
    margin-bottom: 8px;

    > input[disabled] + span {
      opacity: .5 !important;
      cursor: not-allowed !important;
    }
  }

  &[for] > span,
  &.label-for > span {
    cursor: pointer;
  }

  &[for]:last-child,
  &.label-for:last-child {
    margin-bottom: 0;
  }

  &.label-top {
    margin-bottom: 4px;
  }

  &.label-top span {
    font-size: 13px;
    font-weight: 500;
  }

  // Extra
  i.icon-label,
  svg.icon-label {
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
    text-align: center;
    width: 18px;
    height: 24px;
    line-height: 24px;
    font-size: 18px;

    &.opac {
      opacity: .5;
    }
  }
}

// Input - All Types
.field {
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  z-index: 20;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 19px;
  font-family: $inputs_font;
  font-weight: 400;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 48px 24px;
  border-radius: .1em;
  border-style: solid;
  border-width: 1px;
  background-position: center right;
}

input.field,
select.field {
  height: 41px;
  min-height: 41px;
  max-height: 41px;
}

select.field option::first-letter,
.input-select.field option::first-letter,
.input-select.field {
  text-transform: capitalize;
}

.field.disabled {
  cursor: not-allowed !important;
}

.field.icon {
  padding-right: 48px;
}

// Input - Icons
.field.light_XXX {
  // background-image: url('data:image/svg+xml;utf8,XXX');
  background-image: url('data:image/svg+xml;base64,XXX') !important;
}

.field.dark_XXX {
  // background-image: url('data:image/svg+xml;utf8,XXX');
  background-image: url('data:image/svg+xml;base64,XXX') !important;
}

.field.gray_XXX {
  // background-image: url('data:image/svg+xml;utf8,XXX');
  background-image: url('data:image/svg+xml;base64,XXX') !important;
}

.field.gray_alternate_email {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="#868686" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjODY4Njg2IiBkPSJNMjAgNEg0Yy0xLjEgMC0xLjk5LjktMS45OSAyTDIgMThjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY2YzAtMS4xLS45LTItMi0yem0wIDRsLTggNS04LTVWNmw4IDUgOC01djJ6Ii8+PC9zdmc+') !important;
}

.field.gray_vpn_key {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="#868686" d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjODY4Njg2IiBkPSJNMTIuNjUgMTBDMTEuODMgNy42NyA5LjYxIDYgNyA2Yy0zLjMxIDAtNiAyLjY5LTYgNnMyLjY5IDYgNiA2YzIuNjEgMCA0LjgzLTEuNjcgNS42NS00SDE3djRoNHYtNGgydi00SDEyLjY1ek03IDE0Yy0xLjEgMC0yLS45LTItMnMuOS0yIDItMiAyIC45IDIgMi0uOSAyLTIgMnoiLz48L3N2Zz4=') !important;
}

.field.dark_alternate_email {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="black" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJibGFjayIgZD0iTTIwIDRINGMtMS4xIDAtMS45OS45LTEuOTkgMkwyIDE4YzAgMS4xLjkgMiAyIDJoMTZjMS4xIDAgMi0uOSAyLTJWNmMwLTEuMS0uOS0yLTItMnptMCA0bC04IDUtOC01VjZsOCA1IDgtNXYyeiIvPjwvc3ZnPg==') !important;
}

.field.light_vpn_key {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTEyLjY1IDEwQzExLjgzIDcuNjcgOS42MSA2IDcgNmMtMy4zMSAwLTYgMi42OS02IDZzMi42OSA2IDYgNmMyLjYxIDAgNC44My0xLjY3IDUuNjUtNEgxN3Y0aDR2LTRoMnYtNEgxMi42NXpNNyAxNGMtMS4xIDAtMi0uOS0yLTJzLjktMiAyLTIgMiAuOSAyIDItLjkgMi0yIDJ6Ii8+PC9zdmc+') !important;
}

.field.light_alternate_email {
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTIwIDRINGMtMS4xIDAtMS45OS45LTEuOTkgMkwyIDE4YzAgMS4xLjkgMiAyIDJoMTZjMS4xIDAgMi0uOSAyLTJWNmMwLTEuMS0uOS0yLTItMnptMCA0bC04IDUtOC01VjZsOCA1IDgtNXYyeiIvPjwvc3ZnPg==') !important;
}

.field.light_person {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTEyIDEyYzIuMjEgMCA0LTEuNzkgNC00cy0xLjc5LTQtNC00LTQgMS43OS00IDQgMS43OSA0IDQgNHptMCAyYy0yLjY3IDAtOCAxLjM0LTggNHYyaDE2di0yYzAtMi42Ni01LjMzLTQtOC00eiIvPjwvc3ZnPg==') !important;
}

.field.light_cake {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M12 6c1.11 0 2-.9 2-2 0-.38-.1-.73-.29-1.03L12 0l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm4.6 9.99l-1.07-1.07-1.08 1.07c-1.3 1.3-3.58 1.31-4.89 0l-1.07-1.07-1.09 1.07C6.75 16.64 5.88 17 4.96 17c-.73 0-1.4-.23-1.96-.61V21c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-4.61c-.56.38-1.23.61-1.96.61-.92 0-1.79-.36-2.44-1.01zM18 9h-5V7h-2v2H6c-1.66 0-3 1.34-3 3v1.54c0 1.08.88 1.96 1.96 1.96.52 0 1.02-.2 1.38-.57l2.14-2.13 2.13 2.13c.74.74 2.03.74 2.77 0l2.14-2.13 2.13 2.13c.37.37.86.57 1.38.57 1.08 0 1.96-.88 1.96-1.96V12C21 10.34 19.66 9 18 9z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTEyIDZjMS4xMSAwIDItLjkgMi0yIDAtLjM4LS4xLS43My0uMjktMS4wM0wxMiAwbC0xLjcxIDIuOTdjLS4xOS4zLS4yOS42NS0uMjkgMS4wMyAwIDEuMS45IDIgMiAyem00LjYgOS45OWwtMS4wNy0xLjA3LTEuMDggMS4wN2MtMS4zIDEuMy0zLjU4IDEuMzEtNC44OSAwbC0xLjA3LTEuMDctMS4wOSAxLjA3QzYuNzUgMTYuNjQgNS44OCAxNyA0Ljk2IDE3Yy0uNzMgMC0xLjQtLjIzLTEuOTYtLjYxVjIxYzAgLjU1LjQ1IDEgMSAxaDE2Yy41NSAwIDEtLjQ1IDEtMXYtNC42MWMtLjU2LjM4LTEuMjMuNjEtMS45Ni42MS0uOTIgMC0xLjc5LS4zNi0yLjQ0LTEuMDF6TTE4IDloLTVWN2gtMnYySDZjLTEuNjYgMC0zIDEuMzQtMyAzdjEuNTRjMCAxLjA4Ljg4IDEuOTYgMS45NiAxLjk2LjUyIDAgMS4wMi0uMiAxLjM4LS41N2wyLjE0LTIuMTMgMi4xMyAyLjEzYy43NC43NCAyLjAzLjc0IDIuNzcgMGwyLjE0LTIuMTMgMi4xMyAyLjEzYy4zNy4zNy44Ni41NyAxLjM4LjU3IDEuMDggMCAxLjk2LS44OCAxLjk2LTEuOTZWMTJDMjEgMTAuMzQgMTkuNjYgOSAxOCA5eiIvPjwvc3ZnPg==') !important;
}

.field.light_swap_horiz {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTYuOTkgMTFMMyAxNWwzLjk5IDR2LTNIMTR2LTJINi45OXYtM3pNMjEgOWwtMy45OS00djNIMTB2Mmg3LjAxdjNMMjEgOXoiLz48L3N2Zz4=') !important;
}

.field.light_swap_vert {
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTE2IDE3LjAxVjEwaC0ydjcuMDFoLTNMMTUgMjFsNC0zLjk5aC0zek05IDNMNSA2Ljk5aDNWMTRoMlY2Ljk5aDNMOSAzeiIvPjwvc3ZnPg==') !important;
}

.field.dark_swap_vert {
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="black" d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJibGFjayIgZD0iTTE2IDE3LjAxVjEwaC0ydjcuMDFoLTNMMTUgMjFsNC0zLjk5aC0zek05IDNMNSA2Ljk5aDNWMTRoMlY2Ljk5aDNMOSAzeiIvPjwvc3ZnPg==') !important;
}

.field.light_youtube_searched_for {
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M17.01 14h-.8l-.27-.27c.98-1.14 1.57-2.61 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 3-6.5 6.5H2l3.84 4 4.16-4H6.51C6.51 7 8.53 5 11.01 5s4.5 2.01 4.5 4.5c0 2.48-2.02 4.5-4.5 4.5-.65 0-1.26-.14-1.82-.38L7.71 15.1c.97.57 2.09.9 3.3.9 1.61 0 3.08-.59 4.22-1.57l.27.27v.79l5.01 4.99L22 19l-4.99-5z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTE3LjAxIDE0aC0uOGwtLjI3LS4yN2MuOTgtMS4xNCAxLjU3LTIuNjEgMS41Ny00LjIzIDAtMy41OS0yLjkxLTYuNS02LjUtNi41cy02LjUgMy02LjUgNi41SDJsMy44NCA0IDQuMTYtNEg2LjUxQzYuNTEgNyA4LjUzIDUgMTEuMDEgNXM0LjUgMi4wMSA0LjUgNC41YzAgMi40OC0yLjAyIDQuNS00LjUgNC41LS42NSAwLTEuMjYtLjE0LTEuODItLjM4TDcuNzEgMTUuMWMuOTcuNTcgMi4wOS45IDMuMy45IDEuNjEgMCAzLjA4LS41OSA0LjIyLTEuNTdsLjI3LjI3di43OWw1LjAxIDQuOTlMMjIgMTlsLTQuOTktNXoiLz48L3N2Zz4=') !important;
}

.field.light_supervisor_account {
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTE2LjUgMTJjMS4zOCAwIDIuNDktMS4xMiAyLjQ5LTIuNVMxNy44OCA3IDE2LjUgN0MxNS4xMiA3IDE0IDguMTIgMTQgOS41czEuMTIgMi41IDIuNSAyLjV6TTkgMTFjMS42NiAwIDIuOTktMS4zNCAyLjk5LTNTMTAuNjYgNSA5IDVDNy4zNCA1IDYgNi4zNCA2IDhzMS4zNCAzIDMgM3ptNy41IDNjLTEuODMgMC01LjUuOTItNS41IDIuNzVWMTloMTF2LTIuMjVjMC0xLjgzLTMuNjctMi43NS01LjUtMi43NXpNOSAxM2MtMi4zMyAwLTcgMS4xNy03IDMuNVYxOWg3di0yLjI1YzAtLjg1LjMzLTIuMzQgMi4zNy0zLjQ3QzEwLjUgMTMuMSA5LjY2IDEzIDkgMTN6Ii8+PC9zdmc+') !important;
}

// Input - Select
select.field,
.input-select.field {
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" viewBox="0 0 24 24"><path fill="white" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTE2LjU5IDguNTlMMTIgMTMuMTcgNy40MSA4LjU5IDYgMTBsNiA2IDYtNnoiLz48L3N2Zz4=');
  cursor: pointer;
  //white-space: nowrap;
}

// Input - Textarea
textarea.field {
  overflow: auto;
  height: 276px;
  min-height: 276px;
  max-height: 480px;
  resize: vertical;
}

textarea.field.small {
  height: 41px;
  min-height: 41px;
  max-height: 120px;
}

textarea.field.medium {
  height: 120px;
  min-height: 120px;
  max-height: 120px;
}

// Input - Radio, Checkbox
input[type=radio].field + span:before,
input[type=checkbox].field.circle + span:before {
  border-radius: 100%;
}

input[type=checkbox].field + span:before,
input[type=radio].field.square + span:before {
  border-radius: .1em;
}

.checkbox {
  display: none;
  visibility: hidden;
  opacity: 0;
  width: 5px;
  height: 5px;
  margin: 0;
  padding: 0;
}

.checkbox + span {
  padding-left: 34px;
}

label.label span[class^="align_"] {
  position: absolute;
  z-index: 10;
  padding: 0;
  margin: 0;
  float: none;
  width: 24px;
  height: 24px;
}

label.label span.align_center-top {
  left: 50%;
  right: auto;
  top: -12px;
  bottom: auto;
  margin-left: -12px;
}

label.label span.align_middle-left {
  left: -12px;
  right: auto;
  top: 50%;
  bottom: auto;
  margin-top: -12px;
}

label.label span.align_middle-right {
  left: auto;
  right: -12px;
  top: 50%;
  bottom: auto;
  margin-top: -12px;
}

label.label span.align_center-bottom {
  left: 50%;
  right: auto;
  top: auto;
  bottom: -12px;
  margin-left: -12px;
}

.checkbox + span:before {
  content: ' ';
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 24px;
  height: 24px;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  border-style: solid;
  border-width: 1px;
}

.checkbox:checked + span:before {
  border-width: 0;
  //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path fill="white" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6Ii8+PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik05IDE2LjJMNC44IDEybC0xLjQgMS40TDkgMTkgMjEgN2wtMS40LTEuNEw5IDE2LjJ6Ii8+PC9zdmc+');
}

.checkbox.disabled + span {
  cursor: not-allowed !important;
}

// Messages and Errors
p.info {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 12px;
  line-height: 1.4em;
  font-weight: bold;

  svg.icon {
    float: left;
    width: 20px;
    height: 16px;
    fill: currentColor;

    + span.txt {
      padding-left: 20px;
    }
  }

  span.txt {
    display: block;
    line-height: inherit;
  }
}

// Modals
.inputs-active {
  select.field,
  .input-select.field {
    //background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="48"><path fill="white" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/></svg>') !important;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjQ4Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTcuNDEgMTUuNDFMMTIgMTAuODNsNC41OSA0LjU4TDE4IDE0bC02LTYtNiA2eiIvPjwvc3ZnPg==') !important;
  }

  .inputs-modals {
    display: block;
  }
}

.inputs-modals {
  display: none;
  position: absolute;
  z-index: 25;
  top: 100%;
  left: 0;
  right: 0;
  bottom: auto;
  margin-top: -3px;
  padding: 0;

  .inner {
    position: relative;
    z-index: 10;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding-top: 1px;

    .option,
    a {
      color: #fff;
      float: left;
      width: 100%;
      font-size: 14px;
      line-height: 14px;
      padding: 12px 15px;
      margin: -1px 0 0;
      border-top: 1px solid rgba(255, 255, 255, .1);
      border-bottom: 1px solid rgba(255, 255, 255, .1);
      text-transform: capitalize;

      &:hover,
      &[data-active="true"] {
        background: rgba(255, 255, 255, .05);
      }

      &:first-child {border-top: none;}
    }
  }

  .insert {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 5px 0;

    input.field {
      background: transparent;
      border-bottom: 1px solid rgba(255,255,255,.2);
    }
  }

  .scroll {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
  }

  .opac {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// RESPONSIVE - Desktop and Tablet
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop_tablet} {
  .inputs-modals-columns {
    left: auto;
    width: 728px;

    a {
      border-right: 1px solid rgba(255, 255, 255, .1);
    }

    &.x4 a {
      width: 25%;
      &:nth-child(4n+0) {border-right: none;}
      &:nth-child(-n+4) {border-top: none;}
    }
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  label.label.label-m-half {
    width: 50%;
  }
}
