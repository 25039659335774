/* START MODULE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.main-header [data-active-module="search"] {
  .module-search {
    display: block;
  }
}

.module-search {
  .results {
    .heading {
      font-size: 16px;
      line-height: 36px;
      font-weight: 400;
      padding: 0 15px;
      background: #000;
    }

    .link {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      padding: 0 15px;
      line-height: 30px;
      color: #fff;

      strong {
        font-weight: 400;
      }

      &:hover,
      &[data-active="true"] {
        color: #000;
        background: #9e9e9e;

        .count {
          color: #5c5c5c;
        }
      }

      .count {
        font-weight: 400;
        font-size: .9em;
        line-height: inherit;
        color: #6b6b6b;
      }
    }
  }
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  .module-search {
    .live-form {
      margin-bottom: -10px;
    }
  }
}

// RESPONSIVE - Tablet and Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet_mobile} {
  .module-search {
    &.under-header {
      .section-full {
        max-height: 100%;
      }
    }
  }
}


