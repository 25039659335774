/* START MODULE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.main-header [data-active-module="notifications"] {
  .module-notifications {
    display: block;
  }
}

.module-notifications {
  .global-preview.loading,
  [data-global="preview-loading"] {
    display: none;
  }

  .card-partners {
    .data-item-description {
      display: none;
    }
  }
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  .module-notifications {
    .listing-notifications {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 479px;
      min-height: 130px;
    }
  }
}

// RESPONSIVE - Tablet and Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet_mobile} {
  .main-header [data-active-module="notifications"] {
    .module-notifications {
      visibility: visible;
      opacity: 1;
      z-index: 50;

      &.under-header {
        .section-full {
          left: 0;
        }
      }
    }
  }

  .module-notifications {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    z-index: -999;

    &.under-header {
      .section-full {
        height: 100%;
        width: 85%;
        float: right;
        left: 85%;
        transition: left .2s ease-in-out;
      }
    }
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* START MODULE
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .module-notifications {
    .cards {
      a.fake,
      div.fake {
        .play.on-hover {
          display: block;
        }
      }
    }
  }
}

