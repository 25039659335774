/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.btn {
  font-family: $inputs_font;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: .1em;
  font-size: 1.14rem;
  line-height: 1em;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0;
  margin: 0;
  padding: 12px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  position: relative;

  // Animations
  &:hover {
    cursor: pointer !important;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &:active {
    margin: 1px 0 -1px 0;
  }

  // Icons
  i.icon,
  svg.icon {
    vertical-align: top;
    padding: 0;
    margin: -4px;
    margin-right: 8px;
    width: 23px;
    height: 23px;

    &.solo {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &.right {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  // Size and Form
  &.large {
    padding: 15px 10px;
    font-size: 1.9em;

    svg.icon {
      width: 39px;
      height: 39px;
    }
  }

  &.little {
    padding: 9px 5px;
    font-size: .9em;

    svg.icon {
      width: 23px;
      height: 23px;
    }
  }

  &.medium {
    padding: 13px;
    font-size: 1.2em;

    svg.icon {
      width: 28px;
      height: 28px;
      margin: -6px 8px -8px -4px;
    }
  }

  &.small {
    padding: 6px;
    font-size: .9em;

    svg.icon {
      margin: -3px;
      margin-right: 4px;
      width: 19px;
      height: 19px;

      &.right {
        margin-left: 4px;
        margin-right: 0;
      }
    }
  }

  &.flattened {
    padding: 6px 3px;

    svg.icon {
      margin: -2px;
      margin-right: 4px;
      width: 19px;
      height: 19px;
    }
  }

  &.full {
    width: 100%;
  }

  &.minw {
    min-width: 220px;
  }

  // Colors
  &.transparent {
    background: transparent;

    &.loading {
      background: transparent !important;
    }

    &:hover {
      background: transparent;
    }
  }

  // Status
  &[disabled],
  &.disabled {
    opacity: .4 !important;
    cursor: not-allowed !important;

    &:active {
      margin: 0 !important;
    }
  }

  // Loading
  &.loading {
    color: rgba(0, 0, 0, 0) !important;
    cursor: wait !important;

    &:active {
      margin: 0 !important;
    }
  }

  &.loading:after {
    content: '';
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    margin-top: -12px;
    margin-left: -12px;
    width: 24px;
    height: 24px;
    //<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/></svg>
    background: transparent url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTEyIDZ2M2w0LTQtNC00djNjLTQuNDIgMC04IDMuNTgtOCA4IDAgMS41Ny40NiAzLjAzIDEuMjQgNC4yNkw2LjcgMTQuOGMtLjQ1LS44My0uNy0xLjc5LS43LTIuOCAwLTMuMzEgMi42OS02IDYtNnptNi43NiAxLjc0TDE3LjMgOS4yYy40NC44NC43IDEuNzkuNyAyLjggMCAzLjMxLTIuNjkgNi02IDZ2LTNsLTQgNCA0IDR2LTNjNC40MiAwIDgtMy41OCA4LTggMC0xLjU3LS40Ni0zLjAzLTEuMjQtNC4yNnoiLz48L3N2Zz4=') no-repeat center center / 24px 24px;
    @include animRotating($duration: 2s, $to: 360deg, $count: infinite);
  }

  // Split
  &.split {
    padding: 0 !important;
    border: none !important;
    border-radius: 2px !important;
    min-width: 135px;

    .split-left {
      display: inline-block;
      vertical-align: top;
      padding: 0 10px;
      line-height: 28px;
    }

    .split-right {
      white-space: normal;
      float: left;
      background: #fff;
      color: #000;
      padding: 2px 4px;
      border-radius: 2px 0 0 2px;

      .split-top,
      .split-bottom {
        float: left;
        width: 100%;
        text-align: center;
      }

      .split-top {
        height: 15px;
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        margin-bottom: -1px;
      }

      .split-bottom {
        height: 11px;
        font-size: 11px;
        line-height: 11px;
        margin-top: -1px;
      }
    }
  }
}

.-g-small-view-all:hover {
  opacity: .6;
}

.-g-small-view-all {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  font-weight: bold;
  color: #fbca27;
}

.-g-small-view-all_svg {
  width: 24px;
  height: 28px;
}

html[dir="rtl"] .-g-small-view-all_svg {
  transform: scaleX(-1);
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* START COMPONENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .btn {
    // Size and Form
    &.large {
      font-size: 1.4em;
    }

    &.m-full {
      width: 100%;
    }
  }
}