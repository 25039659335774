@import '../../framework/variables_settings';
:root {
  --primary_color: #ec1c24;
  --primary_color_darker: #c8181e;
  --secondary_color: #f2f580;
  --secondary_color_darker: #d9dc6f;
}

/* MAIN COLORS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$primary_bg_color: #0c0c0c;
$secondary_bg_color: #000;
$primary_txt_color: #fff;

$primary_color: #ec1c24;
$primary_color_darker: #c8181e;

$secondary_color: #f2f580;
$secondary_color_darker: #d9dc6f;

$input_placeholder_color: rgba(255, 255, 255, .55);
$input_placeholder_color_focus: rgba(255, 255, 255, .15);

$input_text_color: #d1d1d1;
$input_border_color: rgba(0, 0, 0, 0);
$input_bg_color: #383838;

$input_text_color_focus: #000;
$input_border_color_focus: rgba(0, 0, 0, 0);
$input_bg_color_focus: #e3e3e3;

$nav_opacity: rgba(0, 0, 0, .85);
$nav_bg: rgba(0,0,0,1);

$header_lines_color: rgba(255, 255, 255, .08);

$footer_top_border: #181818;

/* BUTTON COLORS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$btn_primary_color: $primary_color;
$btn_primary_color_hover: $primary_color_darker;

$btn_secondary_color: $secondary_color;
$btn_secondary_color_hover: $secondary_color_darker;

$btn_green: #028A0F; //#04b325;
$btn_green_hover: #02871b;
$btn_yellow: #fbca27;
$btn_yellow_hover: #ebba15;
$btn_red: #ec1c24;
$btn_red_hover: #af1318;
$btn_blue: #3faeea;
$btn_blue_hover: #2f93c9;
$btn_black: #151515;
$btn_black_hover: #0d0d0d;
$btn_gray: #202020;
$btn_gray_hover: #2f2f2f;
$btn_white: #fff;
$btn_white_hover: #eaeaea;

/* START THEME
- - - - - - - - - - - - - - - - - - - - - - - - - - */
::-moz-selection {
  background: $primary_color;
  color: #fff;
}

::selection {
  background: $primary_color;
  color: #fff;
}

.secondary-color {
  color: $secondary_color !important;
}

.secondary-bg-color {
  background-color: $secondary_color !important;
}

.primary-color {
  color: $primary_color !important;
}

.primary-bg-color {
  background-color: $primary_color !important;
}

html,
body {
  background: #000;
  color: $primary_txt_color;
}

.global-no-thumb {
  background: rgba(255, 255, 255, .1);

  i.icon,
  svg.icon {
    color: rgba(255, 255, 255, .1);
  }
}

.main-content {
  background: $secondary_bg_color;
}

.module-header_user span.badge {
  color: #fff;
}

.main-carousel .main-swiper-pagination span.bullet.active:after,
.module-header_user span.badge {
  background: $primary_color;
}

.checkbox:checked + span + .plan-payment {
  background: $primary_color_darker;
}

a, .card-video-inline .h6 a span {
  color: $secondary_color;
}

.popup-content.secondary {
  background: $secondary_color;
}

.home-page ul.ticker green {
  color: $btn_green;
}

.checkbox.green:checked + span:before {
  background-color: $btn_green;
}

.card-marketplace .info-content .price-discount {
  color: $btn_green_hover;
}

.home-page ul.ticker yellow {
  color: $btn_yellow;
}

.home-page ul.ticker red {
  color: $btn_red;
}

.module-header_user span.dot.red {
  background: $btn_red;
}

.listing-channels-logos {
  a.all {
    color: #fff;
  }

  a.all,
  a.logo-box {
    background: rgba(255, 255, 255, .02);
    border: 1px solid $header_lines_color;
  }

  a.all:hover,
  a.logo-box:hover {
    border-color: rgba(255, 255, 255, .2);
  }
}

/* ALL GREEN BUTTONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-comments .button-join-now,
.card-marketplace.is-unlocked .info-content,
.module-portal .button-intro-left,
.module-header_nav .button-color,
.main-content-marketplace .button-continue,
.plan-payment .button-checkout-green,
.video-overlay_unlock_unavailable .button-unlock-denied,
.video-overlay_unlock_denied .button-unlock-denied,
.video-overlay_unlock_form .button-unlock,
.popup-channel_trailer .button-account,
.btn.btn-main-checkout,
.btn.btn-market-watch-now,
.btn.cross-btn,
.play-card-ad .this-box .line-two .btn,
.btn.btn-sign-in,
.btn.btn-support-form,
.btn.btn-recover-password,
.btn.-mf-button,
.btn.btn-network-unlock,
.module-marketplace-unpsell .btn.button-unlock,
.vue-portal-target .btn.button-unlock,
.btn.-pvwaf-button,
.btn.-isu-btn,
{
  background: $btn_green;

  &:hover {
    background: $btn_green_hover;
  }
}

/* ALL YELLOW BUTTONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.popup-upgrade_yellow_bar .button-upgrade,
.module-notifications .button-intro-left,
.module-header_buttons .btn.btn-two,
.module-header_cta .button-color,
.row-today-deal .button-color,
.btn.renew-form-btn,
.btn.-alp-toggle-btn-guest,
.btn.btn-load-more-yellow,
.btn.marketplace-btn-go,
.btn.btn-network-browsing,
.btn.btn-channel-browsing
{
  background: $btn_yellow;
  color: #000;
  font-weight: 700;

  &:hover {
    background: $btn_yellow_hover;
    color: #000;
  }
}

/* ALL BLUE BUTTONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.btn.profiles-partners-button
{
  background: $btn_green;
  color: #fff;
  font-weight: 700;

  &:hover {
    background: $btn_green_hover;
  }
}

/* ALL WHITE BUTTONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-portal .button-intro-right,
.module-notifications .button-intro-right,
.module-header_cta .button-back,
.module-footer-carousel .button-prev,
.module-footer-carousel .button-next,
.btn.button-new-user,
.btn.button-cancellation,
.btn.btn-load-more-white
{
  background: $btn_white;
  color: #000;

  &:hover {
    background: $btn_white_hover;
  }
}

.btn.btn-load-more-darkgrey
{
  background: #3c3c3c;
  color: #f4f4f4;

  &:hover {
    background: #262626;
  }
}

/* ALL BLACK BUTTONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.popup-video_download .button-download-photos,
.plan-payment .button-checkout-black,
.tabs-toggles-playlists .button-delete,
.tabs-toggles-favorites .button-delete
{
  background: $btn_black;

  &:hover {
    background: $btn_black_hover;
  }
}

/* ALL GRAY BUTTONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-comments .button-post,
.btn.btn-load-more-gray,
.btn.-mptd-btn-more
{
  background: $btn_gray;

  &:hover {
    background: $btn_gray_hover;
  }
}

/* ALL BORDERED BUTTONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.btn.bordered {
  border-color: #fff;
  background: transparent;

  &:hover {
    border-color: $primary_color;
    color: $primary_color;
    background: transparent;
  }
}

.module-notifications .btn.bordered {
  border-color: #3c3c3c;
  background: transparent;

  &:hover {
    border-color: #fff;
    color: #fff;
    background: transparent;
  }
}

/* ADD STYLE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@import 'header';
@import 'framework_components';
@import 'svg_colors';
@import 'others';
