/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.notification {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: $grid_gap ($grid_gap * 2);
  border-radius: .1em;

  p {
    font-size: 1rem;
  }

  p a.link {
    font-weight: 500;
    color: inherit;
    text-decoration: underline;
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .notification {
    padding: $grid_gap;
  }
}
