/* START MODULE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
$overlay_z_index: 50;
$preview_z_index: 45;
$player_z_index: 40;
$cover_z_index: 35;

// VIDEO - Container
.video-container {
  position: relative;
}

// VIDEO - Overlay
.video-overlay {
  position: absolute;
  z-index: $overlay_z_index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
}

.video-overlay.set-bg {
  background: rgba(0, 0, 0, .6);
}

.video-overlay-inner {
  position: absolute;
  z-index: 10;
  right: 0;
  left: 0;
}

.video-overlay-inner.set-top {
  top: 0;
  bottom: auto;

  &.set-bg {
    background-image: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, 0));
  }
}

.video-overlay-inner.set-middle {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.video-overlay-inner.set-bottom {
  top: auto;
  bottom: 0;

  &.set-bg {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .75));
  }
}

// VIDEO - Preview
.global-preview,
[data-global="video-preview"] {
  z-index: $preview_z_index;
}

// VIDEO - Player
.video-player-container {
  position: absolute;
  z-index: $player_z_index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .video-part {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  // Videos
  video {
    height: 100%;
  }
}

.video-overlay-fake-play {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  margin: -48px 0 0 -48px;
  width: 96px;
  height: 96px;
  border: none;
  background: rgba(0,0,0,.1);
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;

  svg {
    float: left;
    width: 86px;
    height: 86px;
  }
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  /* VIDEO PAGE
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  // VIDEO - Overlay
  .video-overlay-inner {
    padding: ($grid_gap * 2) 0;
  }
}

// RESPONSIVE - Tablet
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet} {
  /* START MODULE
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  // VIDEO - Container
  .video-container {
    overflow: hidden;
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* START MODULE
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  // VIDEO - Container
  .video-container {
    overflow: hidden;
  }
}
