/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.tabs-toggles {
  display: inline-block;
  vertical-align: top;
  width: 100%;

  // TABS - Navigation
  .tab-nav {
    position: relative;
    margin-bottom: $grid_gap * 2;
    margin-top: -$grid_gap;

    .tab {
      width: auto;
      min-height: 44px;
      min-width: 24px;
    }

    .tab.tab-active:after {
      content: '';
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      bottom: 0;
      top: auto;
      height: 3px;
    }
  }

  .tab + .tab {
    margin-left: 20px;
  }

  .tabs-left {
    float: left;
  }

  .tabs-right {
    float: right;
  }

  .tabs-full {
    float: left;
    width: 100%;
  }

  .tab-nav:after {
    content: '';
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    height: 3px;
  }

  // TABS - Container
  .tab-container {
    .tab {
      width: 100%;
    }
  }
}

// TABS - Navigation
.tab-nav {
  float: left;
  width: 100%;
}

.tab {
  float: left;
  position: relative;
  cursor: pointer;

  // Icons
  span.icon {
    position: absolute;
    top: 50%;
    bottom: auto;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    opacity: .8;

    svg.icon {
      width: inherit;
      height: inherit;
      line-height: inherit;
      font-size: inherit;
      text-align: center;
    }
  }

  span.icon.symbol {
    left: 0;
    right: auto;
    font-size: 22px;
  }

  span.icon.symbol + span.icon.arrow + .txt {
    padding-left: 30px;
  }

  span.icon.symbol + .txt {
    padding-left: 30px;
  }

  span.icon.arrow {
    left: auto;
    right: 0;
    font-size: 22px;
  }

  span.icon.arrow  + .txt {
    padding-right: 30px;
  }

  // Text
  .txt {
    float: left;
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 24px;
    opacity: .7;
  }
}

.tab:hover {
  span.icon,
  .txt {
    opacity: 1;
  }
}

.tabs-toggles .tab-nav.tab-nav-buttons .tab {
  min-height: 41px;
  height: 41px;
}
.tabs-toggles .tab-nav.tab-nav-buttons:after,
.tabs-toggles .tab-nav.tab-nav-buttons .tab.tab-active:after {
  display: none;
}
.tab-nav.tab-nav-buttons .tab .txt {
  opacity: 1;
  padding: 0 15px;
  height: 41px;
  line-height: 39px;
  color: rgba(255, 255, 255, .6);
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
}
.tab-nav.tab-nav-buttons .tab:hover .txt {
  color: #fff;
}
.tab-nav.tab-nav-buttons .tab.tab-active .txt {
  color: #fff;
  cursor: default;
  border-color: #fff;
}
.tabs-toggles .tab-nav.tab-nav-buttons {margin-top: 0;}
.tabs-toggles .tab-nav.tab-nav-buttons .tab + .tab {
  margin: 0;
}

// TABS - Container
.tab-container {
  float: left;
  width: 100%;
}

// TABS - Inner (the html content)
.tab-inner {
  display: none;
  float: left;
  width: 100%;
}

// TABS - ACTIVE
.tab.tab-active {
  span.icon,
  .txt {
    opacity: 1;
  }
}

.tab.tab-active:hover {
  cursor: default;
}

.tab-inner-active {
  display: block;
}

// Custom Tabs
.tab.tab-custom-live-cams {
  .txt span.custom {
    position: relative;
  }

  .txt span.custom:after {
    content: ' ';
    position: absolute;
    top: -1px;
    left: auto;
    right: -7px;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    @include animScaleFromTo($duration: 1s, $from: .4, $to: 1, $count: infinite);
  }
}

.toggles-faqs {
  .tab .txt {
    font-size: 18px;
  }
  .tab-inner p {
    font-size: 14px;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* START COMPONENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .tabs-toggles {

    // TABS - Navigation
    .tab-nav {
      margin-bottom: $grid_gap;
    }
  }
}