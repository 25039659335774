h3.page-description {
  .h4 a {
    color: #fff;
  }
}

/* TOP OFFER BAR
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.top-offers-bar {
  background: $secondary_color;

  a.link {
    color: #000;
  }

  a.link {
    span.badge {
      color: #fff;
      background: #000;
    }
  }
}

/* CARDS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.card-video-inline .h6.actors a:last-child:before {
  color: $primary_txt_color;
}

.cards .bottom {
  background: #171717;
}

.cards .h4 {
  color: #d3d3d3;
}

/* MARKETPLACE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.section-full-marketplace-top {
  background: $footer_top_border;
}

.card-marketplace {
  .inner-popup {
    background: #0d0d0d;
    border: 1px solid #313131;
  }

  .overlay-hovered-effect {
    background: $nav_opacity;
  }

  .top-content {
    border: 1px solid #313131;
    background: #1a1a1a;

    .corner-icon {
      .shape {
        background: $secondary_bg_color;
        border: 1px solid #313131;
      }

      i.icon,
      svg.icon {
        color: #fff;
      }
    }
  }

  .info-content {
    background: #080808;
  }

  .col + .col .info-content {
    border-top: 1px solid #262626;
  }

  .info-content:hover .h6 {
    color: #fff;
  }
}

.card-marketplace-cams .blink {
  background: $footer_top_border;
}

/* ACCOUNT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.tabs-toggles-accordion .tab-container + .tab-container{
  border-top: 1px solid rgba(255, 255, 255, .15);
}

.subscriptions-list {
  a.item:nth-child(even) {
    background: rgba(255, 255, 255, .06)
  }

  a.item:nth-child(odd) {
    background: rgba(255, 255, 255, .1)
  }
}

/* ZIGZAG MODULE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.section-full-zigzag-module .section-full:nth-child(even) {
  background: #202020;
}

.zigzag-text-info .main-title {
  color: #fff;
}

/* PARTNERS PAGE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.featured-partners-section {
  background: #181818;
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  /* ZIGZAG MODULE
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .zigzag-grid {
    .zigzag-gallery .url-thumb {
      border-color: #3c3c3c;
    }
  }
}
