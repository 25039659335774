/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
// General
.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, .6);

  // Type
  &.block {
    height: auto;

    &.bottom {
      top: auto;
    }
  }

  // Background
  &.no-bg {
    background: rgba(0, 0, 0, 0);
  }
}

// Inner
.popup-table {
  display: table;
  width: 100%;
  height: 100%;
  // padding: $grid_gap * 4;
}

.popup-cell {
  display: table-cell;
  width: 100%;

  // Position
  &.top {
    vertical-align: top;
  }

  &.middle {
    vertical-align: middle;
  }

  &.bottom {
    vertical-align: bottom;
  }

  // Background
  &.bg-black {
    background: #000;
  }
}

.popup-content {
  display: block;
  margin: 0 auto;
  //padding: $grid_gap * 4;

  // Size
  &.small {
    max-width: 480px;
  }

  &.large {
    max-width: 768px - ($grid_gap * 4);
  }

  &.grid-standard {
    max-width: $boxed_width;
  }

  &.grid-large {
    max-width: $boxed_large_width;
  }

  &.full {
    max-width: 100%;
  }

  &.cover {
    max-width: 100%;
    height: 100%;
  }

  // Design
  &.light {
    background: #fff;
    color: #000;

    .btn.transparent {
      color: #7c7c7c;
    }
  }

  &.dark {
    background: #000;
  }

  &.secondary {
    color: #000;
  }
}

.popup-main-content {
  position: relative;
  z-index: 5;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.popup-fake-close {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //background: rgba(255, 0, 0, .5);
  cursor: pointer;
}

// Status and Animations
*[data-popup-priority="40"] {
  z-index: 40;
}

*[data-popup-priority="50"] {
  z-index: 50;
}

*[data-popup-priority="60"] {
  z-index: 60;
}

*[data-popup-priority="70"] {
  z-index: 70;
}

*[data-popup-priority="80"] {
  z-index: 80;
}

*[data-popup-priority="90"] {
  z-index: 90;
}

*[data-popup-priority="95"] {
  z-index: 95;
}

*[data-popup-priority="100"] {
  z-index: 100;
}

*[data-popup-priority="105"] {
  z-index: 105;
}

*[data-popup-priority="110"] {
  z-index: 110;
}


*[data-popup-priority="210"] {
  z-index: 220;
}

