/* START MODULE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.main-header [data-active-module="language"] {
  .module-language {
    display: block;
  }
}

.nav-item-languages,
.module-language {
  // Cards
  .card {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    text-align: center;
    padding: 20px 5px;

    .txt {
      padding: 10px 0 0;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .flag {
      display: inline-block;
      vertical-align: top;
      width: 50px;
      height: 25px;
    }
  }
}

// RESPONSIVE - Tablet and Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet_mobile} {
  .main-header [data-active-module="language"] {
    .module-language {
      visibility: visible;
      opacity: 1;
      z-index: 50;

      &.under-header {
        .section-full {
          left: 0;
        }
      }
    }
  }

  .module-language {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    z-index: -999;

    &.under-header {
      .section-full {
        height: 100%;
        width: 85%;
        float: right;
        left: 85%;
        transition: left .2s ease-in-out;
      }
    }
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* START MODULE
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .nav-item-languages,
  .module-language {
    // Cards
    .card {
      text-align: left;
      padding: 5px;
      line-height: 19px;

      .txt {
        padding: 0 0 0 40px;
      }

      .flag {
        float: left;
        width: 30px;
        height: 15px;
      }
    }
  }
}

