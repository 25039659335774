/* GENERAL
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@use 'sass:math';

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: 0;
}

html {
  min-width: 320px;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  text-align: left;
  direction: ltr;

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .hide-ltr {
    display: none;
  }

  .flip-x {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .flip-y {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .flip-ltr-x {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .flip-ltr-y {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
}

html,
body {
  float: left;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  min-width: 320px;
}

html.overflow-hidden,
html.overflow-hidden body {
  overflow: hidden;
}

.clear {
  clear: both;
  display: table;
  height: 0;
  width: 100%;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

a {
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:active,
a:focus {
  outline: none
}

img {
  vertical-align: bottom;
  border: none;
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

picture {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
}

video {
  display: block;
  width: 100%;
  height: auto;
}

iframe {
  display: block;
  width: 100%;
  border: none;
}

.flip-fix-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.flip-fix-y {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.global-preview,
[data-global="video-preview"] {
  position: absolute;
  // z-index: set this specific for your use
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;

  &.loading:after {
    content: '';
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    margin-top: -12px;
    margin-left: -12px;
    width: 24px;
    height: 24px;
    //<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/></svg>
    background: transparent url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTEyIDZ2M2w0LTQtNC00djNjLTQuNDIgMC04IDMuNTgtOCA4IDAgMS41Ny40NiAzLjAzIDEuMjQgNC4yNkw2LjcgMTQuOGMtLjQ1LS44My0uNy0xLjc5LS43LTIuOCAwLTMuMzEgMi42OS02IDYtNnptNi43NiAxLjc0TDE3LjMgOS4yYy40NC44NC43IDEuNzkuNyAyLjggMCAzLjMxLTIuNjkgNi02IDZ2LTNsLTQgNCA0IDR2LTNjNC40MiAwIDgtMy41OCA4LTggMC0xLjU3LS40Ni0zLjAzLTEuMjQtNC4yNnoiLz48L3N2Zz4=') no-repeat center center / 24px 24px;
    @include animRotating($duration: 2s, $to: 360deg, $count: infinite);
  }

  &.anim-bg {
    background: rgba(0, 0, 0, .9);
    @include animBackgroundColorFromTo($duration: 1s, $to: rgba(0, 0, 0, .9));
  }

  &.static-bg {
    background: rgba(0, 0, 0, .1);
  }

  &.no-icon:after {
    display: none;
    visibility: hidden;
    opacity: 1;
  }

  video {
    position: relative;
    z-index: 15;
    height: 100%;
    pointer-events: none;
  }
}

.svg-icon-x-twitter {
  transform: scale(.85) !important;
}

.global-no-thumb {
  position: absolute;
  // z-index: set this specific for your use
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.mini-icon i.icon,
  &.mini-icon svg.icon {
    transform: scale(.4);
  }

  &.small-icon i.icon,
  &.small-icon svg.icon {
    transform: scale(.7);
  }

  i.icon,
  svg.icon {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -64px;
    margin-left: -64px;
    width: 128px;
    height: 128px;
    line-height: 128px;
    font-size: 128px;
    text-align: center;
  }
}

svg.global-fake-play-icon {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin: -45px 0 0 -45px;
  color: #fff;
  width: 90px;
  height: 90px;
  line-height: 70px;
  font-size: 48px;
  //background: #000;
  border-radius: 100%;
  padding: 10px;
  border: 2px solid #fff;
  text-align: center;
}

.hide-overflow {
  overflow: hidden;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.show-inline {
  display: inline-block !important;
}

.cursor-pointer {
  cursor: pointer;
}

.background-cover {
   background-size: cover;
}
.background-contain {
  background-size: contain;
  background-repeat: no-repeat;
}

.lazy {
  opacity: 0;

  &.loaded {
    @include transFadeTo($duration: .3s);
    image-rendering: -webkit-optimize-contrast;
  }
}

/* MAIN CONTENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.main-content {
  display: block;
}

.main-content-full-height {
  min-height: calc(100vh - 77px);
}

.static-language-header {
  + .main-content {
    margin-top: 0;
  }

  .module-header_cta,
  .header-nav-deals,
  .module-header_nav,
  .module-header_user .box-navigation,
  .module-header_user .box-search,
  .module-header_user .box-account,
  .module-header_user .box-notifications,
  .module-header_user .box-hearts,
  .module-header_user .box-webcam,
  .module-header_user .box-games {
    display: none !important;
  }

  .module-header_user .box-language {
    display: block !important;
  }
}

/* GRID SECTIONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.section-full,
.section-boxed {
  display: block;
  margin: 0 auto;
}

.section-full {
  width: 100%;
  max-width: 100%;
}

.section-boxed {
  width: auto;
  max-width: $boxed_width;

  &.sides {
    max-width: $boxed_width + ($grid_gap * 4);
    padding: 0 ($grid_gap * 2);

    &.small {
      max-width: $boxed_small_width + ($grid_gap * 4);
    }

    &.large {
      //max-width: $boxed_medium_width + ($grid_gap * 4);
      max-width: calc(95% + ($grid_gap * 4));
    }
  }

  &.small {
    max-width: $boxed_small_width;
  }

  &.large {
    //max-width: $boxed_medium_width;
    max-width: 100%;
  }

  &.full {
    max-width: 100%;
  }

  &.full-hd {
    max-width: 1920px;
  }
}

@media (min-width: 1920px) {
  .section-boxed {
    &.sides {
      &.large {
        max-width: 1840px;
      }
    }

    &.large {
      max-width: 1800px;
    }
  }
}

/* GRID LAYOUT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
*[data-grid] {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-column-gap: 0;
  grid-row-gap: 0;
  justify-content: stretch;
  align-content: start;
}

/* GRID LAYOUT - DESIGN
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@each $i, $value in (1, 1fr),
        (2, 1fr 1fr),
        (3, 1fr 1fr 1fr),
        (4, 1fr 1fr 1fr 1fr),
        (5, 1fr 1fr 1fr 1fr 1fr),
        (6, 1fr 1fr 1fr 1fr 1fr 1fr) {
  *[data-grid~="#{$i}"] {
    @include data-grid_template($columns: $value);
  }
}

/* GRID LAYOUT - GAPS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
*[data-grid~="gap"] {
  @include grid-gap($gap: $grid_gap, $gap_top: $grid_gap, $gap_bottom: $grid_gap);
}

*[data-grid~="gap-xs"] {
  @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: 0, $gap_bottom: 0);
}

*[data-grid~="sides"] {
  @include grid-gap($gap: $grid_gap, $gap_top: -$grid_gap, $gap_bottom: -$grid_gap);
}

*[data-grid~="sides-xs"] {
  @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: -(math.div($grid_gap, 2)), $gap_bottom: -(math.div($grid_gap, 2)));
}

/* GRID ITEM
- - - - - - - - - - - - - - - - - - - - - - - - - - */
*[data-item] {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  max-width: 100%;
}

/* GRID ITEM - ALIGN
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@each $i, $value in (stretch, stretch),
        (left, start),
        (center, center),
        (right, end) {
  *[data-item~="#{$i}"] {
    -ms-grid-column-align: $value;
    justify-self: $value;
  }
}

@each $i, $value in (stretch, stretch),
        (top, start),
        (middle, center),
        (bottom, end) {
  *[data-item~="#{$i}"] {
    -ms-grid-row-align: $value;
    align-self: $value;
  }
}

/* GRID ITEM - POSITION
- - - - - - - - - - - - - - - - - - - - - - - - - - */
//$rs: $total_rowspan;
//@while $rs > 0 {
//  $r: $total_row;
//  @while $r > 0 {
//    $cs: $total_colspan;
//    @while $cs > 0 {
//      $c: $total_col;
//      @while $c > 0 {
//        *[data-item~="#{$c}#{$r}#{$cs}#{$rs}"] {
//          @include data-item_position($column: #{$c}, $row: #{$r}, $colspan: #{$cs}, $rowspan: #{$rs});
//        }
//        $c: $c - 1;
//      }
//      $cs: $cs - 1;
//    }
//    $r: $r - 1;
//  }
//  $rs: $rs - 1;
//}

$c: $total_col;
@while $c > 0 {
  $cs: $total_colspan;
  @while $cs > 0 {
    *[data-item~="c-#{$c}#{$cs}"] {
      @include data-item_column($column: #{$c}, $colspan: #{$cs});
    }
    $cs: $cs - 1;
  }
  $c: $c - 1;
}

$rs: $total_rowspan;
@while $rs > 0 {
  $r: $total_row;
  @while $r > 0 {
    *[data-item~="r-#{$r}#{$rs}"] {
      @include data-item_row($row: #{$r}, $rowspan: #{$rs});
    }
    $r: $r - 1;
  }
  $rs: $rs - 1;
}

/* ROWS AND COLUMNS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.row {
  display: block;

  &.float {
    float: left;
  }

  &:after {
    content: '';
    clear: both;
    display: table;
    height: 0;
    width: 100%;
  }
}

.row.gap {
  @include grid-gap($gap: $grid_gap, $gap_top: $grid_gap, $gap_bottom: $grid_gap);
}

.row.gap-xs {
  @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: 0, $gap_bottom: 0);
}

.row.sides {
  @include grid-gap($gap: $grid_gap, $gap_top: -$grid_gap, $gap_bottom: -$grid_gap);
}

.row.sides-xs {
  @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: -(math.div($grid_gap, 2)), $gap_bottom: -(math.div($grid_gap, 2)));
}

.row.table {
  display: table;
  width: auto;
  min-width: 100%;
  height: 100%;
}

.row.table > .col {
  float: none;
  display: table-cell;
}

.col {
  float: left;
  width: 100%;
}

.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

.col-auto-width {
  width: auto;
}

@each $i, $value in (10, 10%),
        (14, 14.285%),
        (15, 15%),
        (16, 16.666%),
        (20, 20%),
        (25, 25%),
        (30, 30%),
        (33, 33.333%),
        (35, 35%),
        (40, 40%),
        (45, 45%),
        (50, 50%),
        (55, 55%),
        (60, 60%),
        (65, 65%),
        (66, 66.666%),
        (70, 70%),
        (75, 75%),
        (80, 80%),
        (84, 83.333%),
        (85, 85%),
        (90, 90%),
        (100, 100%) {
  .col-#{$i} {
    width: $value;
  }
}

/* MAIN NETWORKS LOGO
- - - - - - - - - - - - - - - - - - - - - - - - - - */
body .module-header_logo,
.main-content-renew-page {
  // never more then width: 200px and height 60px
  svg.main-logo-amateureuro {
    width: 200px;
    height: 33px;
  }

  svg.main-logo-dirtycosplay {
    width: 185px;
    height: 35px;
  }

  svg.main-logo-hotbabesplus {
    width: 200px;
    height: 33px;
  }

  svg.main-logo-letsdoeit {
    width: 200px;
    height: 33px;
  }

  svg.main-logo-mamacitaz {
    width: 160px;
    height: 45px;
  }

  svg.main-logo-transbella {
    width: 165px;
    height: 30px;
  }

  svg.main-logo-vipsexvault {
    width: 200px;
    height: 33px;
  }

  svg.main-logo-superbe {
    width: 200px;
    height: 50px;
  }

  svg.main-logo-superbemodels {
    width: 220px;
    height: 50px;
  }

  svg.main-logo-rawerotic {
    width: 220px;
    height: 50px;
  }
}

// RESPONSIVE - Desktop Large
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop_large} {
  /* GRID SECTIONS
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  //.section-boxed {
  //  &.sides {
  //    &.large {
  //      max-width: $boxed_large_width + ($grid_gap * 4);
  //    }
  //  }
  //
  //  &.large {
  //    max-width: $boxed_large_width;
  //  }
  //}
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  /* GENERAL
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .d-hide {
    display: none !important;
  }

  /* MAIN CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .main-content {
    margin-top: $desktop_header_height;
  }
  .main-header[data-header-top-bar="active"] {
    + .main-content {
      margin-top: $desktop_header_height + $desktop_market_height;
    }

    &[data-header-navigation="inactive"] {
      + .main-content {
        margin-top: $desktop_market_height;
      }
    }
  }

  .section-boxed {
    &.large {
      max-width: 95%;
    }
  }
}

// RESPONSIVE - Tablet
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet} {
  /* GENERAL
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .t-hide {
    display: none !important;
  }

  /* MAIN CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .main-content {
    margin-top: $tablet_header_height;
  }
  .main-header[data-header-top-bar="active"] {
    + .main-content {
      margin-top: $tablet_header_height + $tablet_market_height;
    }

    &[data-header-navigation="inactive"] {
      + .main-content {
        margin-top: $tablet_market_height;
      }
    }
  }

  /* GRID LAYOUT - DESIGN
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  @each $i, $value in (1, 1fr),
          (2, 1fr 1fr),
          (3, 1fr 1fr 1fr),
          (4, 1fr 1fr 1fr 1fr),
          (5, 1fr 1fr 1fr 1fr 1fr),
          (6, 1fr 1fr 1fr 1fr 1fr 1fr) {
    *[data-grid~="t-#{$i}"] {
      @include data-grid_template($columns: $value);
    }
  }

  /* GRID ITEM - ALIGN
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  @each $i, $value in (stretch, stretch),
          (left, start),
          (center, center),
          (right, end) {
    *[data-item~="t-#{$i}"] {
      -ms-grid-column-align: $value;
      justify-self: $value;
    }
  }

  @each $i, $value in (stretch, stretch),
          (top, start),
          (middle, center),
          (bottom, end) {
    *[data-item~="t-#{$i}"] {
      -ms-grid-row-align: $value;
      align-self: $value;
    }
  }

  /* GRID ITEM - POSITION
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  //$rs: $total_rowspan;
  //@while $rs > 0 {
  //  $r: $total_row;
  //  @while $r > 0 {
  //    $cs: $total_colspan;
  //    @while $cs > 0 {
  //      $c: $total_col;
  //      @while $c > 0 {
  //        *[data-item~="t-#{$c}#{$r}#{$cs}#{$rs}"] {
  //          @include data-item_position($column: #{$c}, $row: #{$r}, $colspan: #{$cs}, $rowspan: #{$rs});
  //        }
  //        $c: $c - 1;
  //      }
  //      $cs: $cs - 1;
  //    }
  //    $r: $r - 1;
  //  }
  //  $rs: $rs - 1;
  //}

  $c: $total_col;
  @while $c > 0 {
    $cs: $total_colspan;
    @while $cs > 0 {
      *[data-item~="t-c-#{$c}#{$cs}"] {
        @include data-item_column($column: #{$c}, $colspan: #{$cs});
      }
      $cs: $cs - 1;
    }
    $c: $c - 1;
  }

  $rs: $total_rowspan;
  @while $rs > 0 {
    $r: $total_row;
    @while $r > 0 {
      *[data-item~="t-r-#{$r}#{$rs}"] {
        @include data-item_row($row: #{$r}, $rowspan: #{$rs});
      }
      $r: $r - 1;
    }
    $rs: $rs - 1;
  }

  /* ROWS AND COLUMNS
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  @each $i, $value in (10, 10%),
          (14, 14.285%),
          (15, 15%),
          (16, 16.666%),
          (20, 20%),
          (25, 25%),
          (30, 30%),
          (33, 33.333%),
          (35, 35%),
          (40, 40%),
          (45, 45%),
          (50, 50%),
          (55, 55%),
          (60, 60%),
          (65, 65%),
          (66, 66.666%),
          (70, 70%),
          (75, 75%),
          (80, 80%),
          (84, 83.333%),
          (85, 85%),
          (90, 90%),
          (100, 100%) {
    .t-col-#{$i} {
      width: $value;
    }
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* GENERAL
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .m-hide {
    display: none !important;
  }

  .global-no-thumb {
    &.small-icon i.icon,
    &.small-icon svg.icon {
      transform: scale(.4);
    }
  }

  /* MAIN CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .main-content {
    margin-top: $mobile_header_height;
  }
  .main-header[data-header-top-bar="active"] {
    + .main-content {
      margin-top: $mobile_header_height + $mobile_market_height;
    }

    &[data-header-navigation="inactive"] {
      + .main-content {
        margin-top: $mobile_market_height;
      }
    }
  }

  /* GRID SECTIONS
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .section-boxed {
    &.sides {
      padding: 0 $grid_gap;
    }
  }

  /* GRID LAYOUT - GAPS
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  *[data-grid~="gap"] {
    @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: math.div($grid_gap, 2), $gap_bottom: math.div($grid_gap, 2));
  }

  *[data-grid~="sides"] {
    @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: -(math.div($grid_gap, 2)), $gap_bottom: -(math.div($grid_gap, 2)));
  }

  /* GRID LAYOUT - DESIGN
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  @each $i, $value in (1, 1fr),
          (2, 1fr 1fr),
          (3, 1fr 1fr 1fr),
          (4, 1fr 1fr 1fr 1fr),
          (5, 1fr 1fr 1fr 1fr 1fr),
          (6, 1fr 1fr 1fr 1fr 1fr 1fr) {
    *[data-grid~="m-#{$i}"] {
      @include data-grid_template($columns: $value);
    }
  }

  /* GRID ITEM - ALIGN
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  @each $i, $value in (stretch, stretch),
          (left, start),
          (center, center),
          (right, end) {
    *[data-item~="m-#{$i}"] {
      -ms-grid-column-align: $value;
      justify-self: $value;
    }
  }

  @each $i, $value in (stretch, stretch),
          (top, start),
          (middle, center),
          (bottom, end) {
    *[data-item~="m-#{$i}"] {
      -ms-grid-row-align: $value;
      align-self: $value;
    }
  }

  /* GRID ITEM - POSITION
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  //$rs: $total_rowspan;
  //@while $rs > 0 {
  //  $r: $total_row;
  //  @while $r > 0 {
  //    $cs: $total_colspan;
  //    @while $cs > 0 {
  //      $c: $total_col;
  //      @while $c > 0 {
  //        *[data-item~="m-#{$c}#{$r}#{$cs}#{$rs}"] {
  //          @include data-item_position($column: #{$c}, $row: #{$r}, $colspan: #{$cs}, $rowspan: #{$rs});
  //        }
  //        $c: $c - 1;
  //      }
  //      $cs: $cs - 1;
  //    }
  //    $r: $r - 1;
  //  }
  //  $rs: $rs - 1;
  //}

  $c: $total_col;
  @while $c > 0 {
    $cs: $total_colspan;
    @while $cs > 0 {
      *[data-item~="m-c-#{$c}#{$cs}"] {
        @include data-item_column($column: #{$c}, $colspan: #{$cs});
      }
      $cs: $cs - 1;
    }
    $c: $c - 1;
  }

  $rs: $total_rowspan;
  @while $rs > 0 {
    $r: $total_row;
    @while $r > 0 {
      *[data-item~="m-r-#{$r}#{$rs}"] {
        @include data-item_row($row: #{$r}, $rowspan: #{$rs});
      }
      $r: $r - 1;
    }
    $rs: $rs - 1;
  }

  /* ROWS AND COLUMNS
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .row.gap {
    @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: math.div($grid_gap, 2), $gap_bottom: math.div($grid_gap, 2));
  }

  .row.sides {
    @include grid-gap($gap: math.div($grid_gap, 2), $gap_top: -(math.div($grid_gap, 2)), $gap_bottom: -(math.div($grid_gap, 2)));
  }

  @each $i, $value in (10, 10%),
          (14, 14.285%),
          (15, 15%),
          (16, 16.666%),
          (20, 20%),
          (25, 25%),
          (30, 30%),
          (33, 33.333%),
          (35, 35%),
          (40, 40%),
          (45, 45%),
          (50, 50%),
          (55, 55%),
          (60, 60%),
          (65, 65%),
          (66, 66.666%),
          (70, 70%),
          (75, 75%),
          (80, 80%),
          (84, 83.333%),
          (85, 85%),
          (90, 90%),
          (100, 100%) {
    .m-col-#{$i} {
      width: $value;
    }
  }

  body .module-header_logo,
  .main-content-renew-page {
    // never more then width: 170px and height 35px
    svg.main-logo-amateureuro {
      width: 170px;
      height: 28px;
    }

    svg.main-logo-dirtycosplay {
      width: 130px;
      height: 25px;
    }

    svg.main-logo-hotbabesplus {
      width: 170px;
      height: 28px;
    }

    svg.main-logo-letsdoeit {
      width: 170px;
      height: 28px;
    }

    svg.main-logo-mamacitaz {
      width: 125px;
      height: 35px;
    }

    svg.main-logo-transbella {
      width: 135px;
      height: 25px;
    }

    svg.main-logo-vipsexvault {
      width: 170px;
      height: 28px;
    }

    svg.main-logo-superbe {
      width: 115px;
      height: 35px;
    }

    svg.main-logo-superbemodels {
      width: 150px;
      height: 30px;
    }

    svg.main-logo-rawerotic {
      width: 150px;
      height: 30px;
    }
  }
}

// RESPONSIVE - Small Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile_small} {
  /* MAIN CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .main-content {
    margin-top: $mobile_small_header_height;
  }
  .main-header[data-header-top-bar="active"] {
    + .main-content {
      margin-top: $mobile_small_header_height + $mobile_small_market_height;
    }

    &[data-header-navigation="inactive"] {
      + .main-content {
        margin-top: $mobile_small_market_height;
      }
    }
  }

  body .module-header_logo,
  .main-content-renew-page {
    // never more then width: 125px and height 35px
    svg.main-logo-amateureuro {
      width: 125px;
      height: 21px;
    }

    svg.main-logo-dirtycosplay {
      width: 100px;
      height: 20px;
    }

    svg.main-logo-hotbabesplus {
      width: 125px;
      height: 21px;
    }

    svg.main-logo-letsdoeit {
      width: 125px;
      height: 21px;
    }

    svg.main-logo-mamacitaz {
      width: 100px;
      height: 30px;
    }

    svg.main-logo-transbella {
      width: 110px;
      height: 20px;
    }

    svg.main-logo-vipsexvault {
      width: 125px;
      height: 21px;
    }
  }
}
