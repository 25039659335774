/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
/* @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../../../fonts/icons/MaterialIcons-Regular.eot");
  src: local("Material Icons"),
  local("MaterialIcons-Regular"),
  url("../../../fonts/icons/MaterialIcons-Regular.woff2") format("woff2"),
  url("../../../fonts/icons/MaterialIcons-Regular.woff") format("woff"),
  url("../../../fonts/icons/MaterialIcons-Regular.ttf") format("truetype");
  font-display: block;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';

  &.top {
    vertical-align: top;
  }

  &.middle {
    vertical-align: middle;
  }

  &.bottom {
    vertical-align: bottom;
  }

  &.regular {
    font-size: inherit;
    line-height: inherit;
  }
} */

svg.icon {
  display: inline-block;
  vertical-align: top;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  &.middle {
    vertical-align: middle;
  }

  &.bottom {
    vertical-align: bottom;
  }

  &.regular {
    width: 24px;
    height: 24px;
  }
}

// Flags
.flag {
  background: transparent no-repeat center center;
  background-size: cover;
}