/* HEADER
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.main-header {
  position: relative;
  z-index: 91;
  width: 100%;
  display: block;

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    transition: top .3s linear 0s;
  }

  &[data-header-navigation="inactive"] {
    .module-header {
      display: none !important;
    }
  }

  &[data-header-top-bar="active"] {
    .module-header {
      .box-notifications,
      .box-hearts,
      .box-webcam,
      .box-games,
      .box-account {
        display: none !important;
      }
    }
  }
}

/* UNDER HEADER CONTENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.under-header {
  display: none;
  position: absolute;
  z-index: 50;
  right: 0;
  left: 0;
  bottom: auto;
  top: 0;
  height: calc(#{$desktop_market_height} + 100vh);

  .section-full {
    position: relative;
    z-index: 10;
  }

  .close {
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    cursor: pointer;
  }
}

// RESPONSIVE - Desktop
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$desktop} {
  /* UNDER HEADER CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .under-header {
    right: -20px;
    left: 0;
    padding-top: $desktop_header_height;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .section-full {
      margin-bottom: 80px;
    }

    .close {
      right: 20px;
      left: 0;
    }
  }

  .main-header[data-header-top-bar="active"] {
    .under-header {
      padding-top: $desktop_header_height + $desktop_market_height;
    }
  }
}

// RESPONSIVE - Tablet and Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet_mobile} {
  /* UNDER HEADER CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .under-header {
    overflow: hidden;

    .section-full {
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}

// RESPONSIVE - Tablet
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$tablet} {
  /* UNDER HEADER CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .under-header {
    padding-top: $tablet_header_height;
  }

  .main-header[data-header-top-bar="active"] {
    .under-header {
      padding-top: $tablet_header_height + $tablet_market_height;
    }
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* UNDER HEADER CONTENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .under-header {
    padding-top: $mobile_header_height;
  }

  .main-header[data-header-top-bar="active"] {
    .under-header {
      padding-top: $mobile_header_height + $mobile_market_height;
    }
  }
}

/* ADD COMPONENTS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@import '_components/html_header_global_social_icons';

/* ADD MODULES
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@import '_modules/module_header';
@import '_modules/module_header_language';
@import '_modules/module_header_notifications';
@import '_modules/module_header_search';
@import '_modules/module_header_marketplace_networks';
@import '_modules/module_header_marketplace_sites';