@import '../stylesheet';

/* START THEME
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_logo {
  // never more then width: 200px and height 60px
  svg.main-logo-thewhiteboxxx {width: 200px; height: 33px;}
  svg.main-logo-scamangels {width: 170px; height: 37px;}
  svg.main-logo-herlimit {width: 145px; height: 55px;}
  svg.main-logo-agirlknows {width: 200px; height: 33px;}
  svg.main-logo-xchimera {width: 200px; height: 33px;}
  svg.main-logo-bitchesabroad {width: 200px; height: 33px;}
  svg.main-logo-relaxxxed {width: 160px; height: 24px;}
  svg.main-logo-questfororgasm {width: 200px; height: 45px;}
  svg.main-logo-kinkyinlaws {width: 195px; height: 25px;}
  svg.main-logo-hornyhostel {width: 175px; height: 33px;}
  svg.main-logo-pornoacademie {width: 200px; height: 33px;}
  svg.main-logo-bumsbus {width: 185px; height: 50px;}
  svg.main-logo-bumsbesuch {width: 200px; height: 42px;}
  svg.main-logo-bumsbuero {width: 200px; height: 55px;}
  svg.main-logo-xxxshades {width: 170px; height: 40px;}
  svg.main-logo-doeprojects {width: 200px; height: 33px;}
  svg.main-logo-mynaughtyalbum {width: 200px; height: 33px;}
  svg.main-logo-latinamilf {width: 200px; height: 50px;}
  svg.main-logo-doegirls {width: 195px; height: 34px;}
}

.module-footer {
  svg.main-logo-scamangels {width: 160px; height: 30px;}
  svg.main-logo-bumsbuero {width: 160px; height: 40px;}
  svg.main-logo-bumsbus {width: 160px; height: 40px;}
  svg.main-logo-herlimit {width: 160px; height: 40px;}
  svg.main-logo-questfororgasm {width: 160px; height: 30px;}
  svg.main-logo-xxxshades {width: 160px; height: 35px;}
  svg.main-logo-latinamilf {width: 200px; height: 50px;}
}

// RESPONSIVE - CUSTOM
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media screen and (min-width: 1000px) and (max-width: 1048px) {
  .module-header_logo svg.main-logo {width: 160px !important;}
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .module-header_logo {
    // never more then width: 170px and height 35px
    svg.main-logo-thewhiteboxxx {width: 170px; height: 28px;}
    svg.main-logo-scamangels {width: 122px; height: 28px;}
    svg.main-logo-herlimit {width: 95px; height: 35px;}
    svg.main-logo-agirlknows {width: 170px; height: 28px;}
    svg.main-logo-xchimera {width: 170px; height: 28px;}
    svg.main-logo-bitchesabroad {width: 170px; height: 28px;}
    svg.main-logo-relaxxxed {width: 110px; height: 15px;}
    svg.main-logo-questfororgasm {width: 150px; height: 33px;}
    svg.main-logo-kinkyinlaws {width: 170px; height: 28px;}
    svg.main-logo-hornyhostel {width: 150px; height: 28px;}
    svg.main-logo-pornoacademie {width: 170px; height: 28px;}
    svg.main-logo-bumsbus {width: 135px; height: 35px;}
    svg.main-logo-bumsbesuch {width: 165px; height: 35px;}
    svg.main-logo-bumsbuero {width: 135px; height: 35px;}
    svg.main-logo-xxxshades {width: 130px; height: 30px;}
    svg.main-logo-doeprojects {width: 170px; height: 28px;}
    svg.main-logo-mynaughtyalbum {width: 170px; height: 28px;}
    svg.main-logo-latinamilf {width: 128px; height: 25px;}
    svg.main-logo-doegirls {width: 170px; height: 28px;}
  }
}

// RESPONSIVE - Mobile Small
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile_small} {
  .module-header_logo {
    // never more then width: 125px and height 35px
    svg.main-logo-thewhiteboxxx {width: 125px; height: 21px;}
    svg.main-logo-scamangels {width: 104px; height: 22px;}
    //svg.main-logo-herlimit {same mobile}
    svg.main-logo-agirlknows {width: 125px; height: 21px;}
    svg.main-logo-xchimera {width: 125px; height: 21px;}
    svg.main-logo-bitchesabroad {width: 125px; height: 21px;}
    //svg.main-logo-relaxxxed {same mobile}
    svg.main-logo-questfororgasm {width: 125px; height: 30px;}
    svg.main-logo-kinkyinlaws {width: 125px; height: 21px;}
    svg.main-logo-hornyhostel {width: 110px; height: 21px;}
    svg.main-logo-pornoacademie {width: 125px; height: 21px;}
    svg.main-logo-bumsbus {width: 125px; height: 35px;}
    svg.main-logo-bumsbesuch {width: 125px; height: 26px;}
    svg.main-logo-bumsbuero {width: 115px; height: 30px;}
    svg.main-logo-xxxshades {width: 110px; height: 25px;}
    svg.main-logo-doeprojects {width: 125px; height: 21px;}
    svg.main-logo-mynaughtyalbum {width: 125px; height: 21px;}
    svg.main-logo-latinamilf {width: 120px; height: 26px;}
    svg.main-logo-doegirls {width: 125px; height: 21px;}
  }
}
