/* CSS GRID LAYOUT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
// - set the grid template
@mixin data-grid_template($columns: auto, $rows: auto) {
  -ms-grid-columns: $columns;
  grid-template-columns: $columns;

  @if $rows != 'auto' {
    -ms-grid-rows: $rows;
    grid-template-rows: $rows;
  }
}

// - set item position
//@mixin data-item_position($column: 1, $row: 1, $colspan: 1, $rowspan: 1) {
//  -ms-grid-column: $column;
//  -ms-grid-column-span: $colspan;
//  -ms-grid-row: $row;
//  -ms-grid-row-span: $rowspan;
//  grid-column: $column / span $colspan;
//  grid-row: $row / span $rowspan;
//}

@mixin data-item_column($column: 1, $colspan: 1) {
  -ms-grid-column: $column;
  -ms-grid-column-span: $colspan;
  grid-column: $column / span $colspan;
}

@mixin data-item_row($row: 1, $rowspan: 1) {
  -ms-grid-row: $row;
  -ms-grid-row-span: $rowspan;
  grid-row: $row / span $rowspan;
}

/* GRID GAP
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@mixin grid-gap($gap, $gap_top, $gap_bottom) {

  @if $gap_top <= 0 {
    margin: $gap_top (-$gap) $gap_bottom (-$gap);
    padding: 0;
  } @else if $gap_bottom <= 0 {
    margin: $gap_top (-$gap) $gap_bottom (-$gap);
    padding: 0;
  } @else {
    margin: 0 (-$gap);
    padding: $gap_top 0 $gap_bottom 0;
  }

  > *[data-item] {
    margin: $gap;
  }

  > .col {
    padding: $gap;
  }
}


