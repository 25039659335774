/* assets/scss/framework/_components/_buttons.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.btn {
  background: $btn_primary_color;
  color: #fff;

  &:hover {
    background: $btn_primary_color_hover;
  }

  &.secondary {
    background: $btn_secondary_color;
    color: #000;

    &:hover {
      background: $btn_secondary_color_hover
    }
  }

  &.transparent {
    color: $primary_txt_color;
  }

  &.loading {
    background: rgba(255, 255, 255, .2) !important;
  }

  i.icon.primary,
  svg.icon.primary {
    color: $primary_color;
  }

  i.icon.secondary,
  svg.icon.secondary {
    color: $secondary_color;
  }
}

/* assets/scss/framework/_components/_carousel.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
/* assets/scss/framework/_components/_form.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
::-webkit-input-placeholder {
  color: $input_placeholder_color;
}

:-moz-placeholder {
  color: $input_placeholder_color;
}

::-moz-placeholder {
  color: $input_placeholder_color;
}

:-ms-input-placeholder {
  color: $input_placeholder_color;
}

::-ms-input-placeholder {
  color: $input_placeholder_color;
}

:placeholder-shown {
  color: $input_placeholder_color;
}

*:focus::-webkit-input-placeholder {
  color: $input_placeholder_color_focus;
}

*:focus:-moz-placeholder {
  color: $input_placeholder_color_focus;
}

*:focus::-moz-placeholder {
  color: $input_placeholder_color_focus;
}

*:focus:-ms-input-placeholder {
  color: $input_placeholder_color_focus;
}

*:focus::-ms-input-placeholder {
  color: $input_placeholder_color_focus;
}

*:focus:placeholder-shown {
  color: $input_placeholder_color_focus;
}

*:active::-webkit-input-placeholder {
  color: $input_placeholder_color_focus;
}

*:active:-moz-placeholder {
  color: $input_placeholder_color_focus;
}

*:active::-moz-placeholder {
  color: $input_placeholder_color_focus;
}

*:active:-ms-input-placeholder {
  color: $input_placeholder_color_focus;
}

*:active::-ms-input-placeholder {
  color: $input_placeholder_color_focus;
}

*:valid {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

*:invalid {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.field:-webkit-autofill {
  box-shadow: 0 0 0 9999px #fff inset;
}

.field:-webkit-autofill:focus,
.field:-webkit-autofill:active {
  box-shadow: 0 0 0 9999px #fff inset;
}

label.label {
  span.req:after {
    color: red;
  }

  span a {
    border-bottom: 1px solid rgba(255, 255, 255, .3);
  }

  i.icon-label,
  svg.icon-label {
    color: rgba(255, 255, 255, .8);
  }
}

.field {
  color: $input_text_color;
  background-color: $input_bg_color;
  border-color: $input_border_color;
}

.field.disabled {
  background-color: $input_bg_color !important;
  border-color: $input_border_color !important;
  color: $input_text_color !important;
  opacity: .7 !important;
}

.checkbox + span:before {
  background-color: $input_bg_color;
  border-color: $input_border_color;
}

.checkbox:checked + span:before {
  background-color: $primary_color;
}

.checkbox + span.white:before {
  background-color: #fff;
}

.checkbox.gray:checked + span:before {
  background-color: $input_bg_color;
}

.checkbox.disabled + span {
  opacity: 0.4 !important;
}

.inputs-errors {
  label.label {
    color: red;
  }

  label.label span.req:after {
    color: #fff;
  }
}

p.info {
  span.txt {
    color: #fff;
  }

  &.green {
    span.txt {
      color: greenyellow;
    }
  }

  &.red {
    span.txt {
      color: red;
    }
  }

  &.gray {
    span.txt {
      color: #ccc;
    }
  }
}

.inputs-modals {
  background: $input_bg_color;
  box-shadow: inset 0 10px 10px 0 rgba(0, 0, 0, 0.3);
  border-top: 3px solid $input_bg_color;
}

.inputs-modals .opac {
  background: rgba(0, 0, 0, .2);
}

/* assets/scss/framework/_components/_gallery.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
/* assets/scss/framework/_components/_icons.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
/* assets/scss/framework/_components/_notifications.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.notification {
  background: rgba(255, 255, 255, .05);
  border: 1px solid rgba(255, 255, 255, .08);
  color: rgba(255, 255, 255, .6);

  &.green {
    background: #1d5414;
  }

  &.red {
    background: #6e2626;
  }

  &.gray {
    background: #252525;
  }
}

/* assets/scss/framework/_components/_pagination.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
//.pagination {
//  color: $primary_txt_color;
//
//  .-this {
//    border: 1px solid rgba(0, 0, 0, 0);
//  }
//
//  .-this.active {
//    border-color: rgba(255, 255, 255, 1);
//  }
//
//  .pages .-this:not(.active):hover {
//    border-color: rgba(255, 255, 255, .2);
//  }
//}

/* assets/scss/framework/_components/_player.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
/* assets/scss/framework/_components/_popup.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.popup {
  background: $nav_opacity;
}

.popup-cell {
  &.bg-black {
    background: #000;
  }
}

.popup-content {
  &.light {
    background: #fff;
    color: #000;
  }

  &.dark {
    background: #000;
  }

  &.secondary {
    color: #000;
  }
}

/* assets/scss/framework/_components/_tabs_toggles.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.tabs-toggles {
  .tab-nav:after {
    background: rgba(255, 255, 255, .15);
  }

  .tab-nav .tab.tab-active:after {
    background: $primary_color;
  }
}

.tab {
  color: $primary_txt_color;
}

.tab.tab-custom-live-cams {
  .txt span.custom:after {
    background: #00cd00;
  }
}

/* assets/scss/framework/_components/_typography.scss
- - - - - - - - - - - - - - - - - - - - - - - - - - */
::-moz-selection {
  background: $primary_color;
  color: #fff;
}

::selection {
  background: $primary_color;
  color: #fff;
}

h1, h2, h3,
h4, h5, h6,
.h0, .h1, .h2, .h3,
.h4, .h5, .h6,
p, .p {
  a:hover {
    color: rgba(255, 255, 255, .75);
  }

  small {
    color: #959595;
  }
}

.color-rgba255-1 {
  color: rgba(255, 255, 255, 1);
}

.color-rgba255-08 {
  color: rgba(255, 255, 255, .8);
}

.color-rgba255-06 {
  color: rgba(255, 255, 255, .6);
}

.hr {
  background: rgba(255, 255, 255, .15);

  &.invert {
    background: rgba(0, 0, 0, .15);
  }
}

table {
  tr {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }

  tr:nth-child(even) {
    background: rgba(255, 255, 255, .06)
  }

  tr:nth-child(odd) {
    background: rgba(255, 255, 255, .1)
  }
}
