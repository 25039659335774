/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
#fluid-player_fluid_context_menu ul {
  width: auto !important;
  margin: 0 !important;
  padding: 10px !important;
}

#fluid-player_fluid_context_menu ul li {
  display: list-item !important;
  width: auto !important;
  margin: 0 !important;
  padding: 3px !important;
  font-size: 13px !important;
}

#fluid_video_wrapper_fluid-player {
  width: 100% !important;
  height: auto !important;
}

#fluid_video_wrapper_fluid-player:before {
  background: transparent center bottom no-repeat;
  background-size: auto;
  background-size: contain;
  content: "";
  width: 200px;
  height: 50px;
  position: absolute;
  top: auto;
  left: auto;
  right: 40px;
  bottom: 40px;
  z-index: 1;
  opacity: 0.35;
}

.fluid_video_wrapper {
  //width: 100% !important;
  //height: 100% !important;
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  #fluid_video_wrapper_fluid-player:before {
    width: 100px;
    height: 25px;
    bottom: 15px;
    right: 15px;
  }
}
