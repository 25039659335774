/* NETWORK LOGO
- - - - - - - - - - - - - - - - - - - - - - - - - - */

/* HEADER
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.symbol-powered-ldi {
  .color-one {fill: silver;}
  .color-two {fill: #323232;}
  .color-three {fill: silver;}
}

/* FOOTER
- - - - - - - - - - - - - - - - - - - - - - - - - -
.symbol-rta-logo {
  .color-one {fill: $primary_txt_color;}
}

.symbol-asacp-logo {
  .color-one {fill: $primary_txt_color;}
}

.symbol-icon-tw {
  .color-one {fill: $primary_txt_color;}
}

.symbol-icon-ins {
  .color-one {fill: $primary_txt_color;}
}

.symbol-icon-yt {
  .color-one {fill: $primary_txt_color;}
}

.symbol-icon-blg {
  .color-one {fill: $primary_txt_color;}
}
*/