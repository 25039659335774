/* HEADER
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.main-header {
  background: transparent;

  //&:hover,
  //[data-active-module] {
  //  .module-header {
  //    background: #000;
  //  }
  //}
}

.under-header {
  background: $nav_opacity;

  .hr {
    background: #818181;
  }

  .section-full {
    background: $nav_bg;
  }
}

/* Header - GENERAL
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header {
  background: transparent;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 65%);
}

strong.nav-live-strong:after {
  background: #00cd00;
}

/* Breadcrumb
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-breadcrumb {
  background: #181818;
}

.module-breadcrumb .this-heading {
  h4 {
    color: #C0C0C0;
  }
}

ul.breadcrumb {
  li + li:before {
    opacity: .5;
  }

  li a {
    color: #C0C0C0;
  }

  li:hover a {
    opacity: .7;
  }
}

/* Header - RIGHT ICONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_user {
  .box:hover span.symbol svg.flag,
  .box:hover span.symbol i.icon,
  .box:hover span.symbol svg.icon {
    opacity: .75;
  }

  span.dot {
    border: 2px solid $secondary_bg_color;
  }
}

/* Header - NAVIGATION
- - - - - - - - - - - - - - - - - - - - - - - - - - */
@media #{$desktop} {
  nav {
    .a {
      strong {
        color: $primary_txt_color;
      }
    }

    ul {
      li:hover span.more,
      li:hover .a strong {
        opacity: .75;
      }
    }

    > ul > li > ul {
      background: rgba(0, 0, 0, .9);
    }

    > ul > li > ul li + li {
      border-top: 1px solid rgba(0, 0, 0, .8);
    }

    > ul > li > ul li:hover {
      background: rgba(0, 0, 0, .8);

      .a strong {
        opacity: 1;
      }
    }
  }
}

@media #{$tablet_mobile} {
  .module-header_nav {
    background: $nav_opacity;
  }

  nav {
    background: $nav_bg;

    > ul > li:not(:first-child) > .a {
      border-top: 1px solid $header_lines_color;
    }

    .a {
      strong {
        color: $primary_txt_color;
      }
    }

    > ul > li > ul {
      li .a strong {
        opacity: .5;
      }
    }
  }
}

/* Header - LANGUAGE
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.nav-item-languages,
.module-language {
  .card {
    background: #1f1f1f;
    border: 1px solid #818181;
    color: #ffffff;
  }
}

/* Header - SOCIAL ICONS
- - - - - - - - - - - - - - - - - - - - - - - - - - */
.module-header_nav .row-nav-social-media {
  border-bottom: 1px solid #818181;
}

.row-nav-social-media .col:not(:last-child) {
  border-right: 1px solid #818181;
}

.row-nav-social-media {
  border-top: 1px solid #818181;

  a {
    color: #fff;
  }
}