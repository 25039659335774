/* START COMPONENT
- - - - - - - - - - - - - - - - - - - - - - - - - - */
// - used for: Favorites, Playlists, Header Notifications
.card-video-inline {
  float: left;
  width: 100%;

  .grid {
    @include data-grid_template($columns: 100px 10px 1fr 25px);
  }

  a.thumb {
    position: relative;
    display: block;

    img {
      position: relative;
      z-index: 5;
    }

    span.duration {
      position: absolute;
      z-index: 10;
      left: 5px;
      top: 5px;
      right: auto;
      bottom: auto;
      height: 24px;
      background: #000;
      color: #fff;
      line-height: 24px;
      letter-spacing: .5px;
      font-weight: 600;
      font-size: 12px;
      padding: 0 10px;
    }
  }

  .h5, .h6 {
    margin: 2px 0;
    padding: 0;
  }

  .h6.actors a:hover,
  .h6 {
    color: rgba(255, 255, 255, .75);
  }

  .h6 a span {
    color: red;
  }

  .h6 a:hover span {
    opacity: .75;
  }

  .h6.actors a:after {
    content: ',\00a0';
  }

  .h6.actors a:last-child:before {
    content: '\00a0\0026\00a0';
  }

  .h6.actors a:nth-last-child(2):after,
  .h6.actors a:first-child:before,
  .h6.actors a:last-child:after {
    display: none !important;
  }
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  /* START COMPONENT
  - - - - - - - - - - - - - - - - - - - - - - - - - - */
  // - used for: Favorites, Playlists, Header Notifications
  .card-video-inline {
    a.thumb {
      span.duration {
        left: 2px;
        top: 2px;
        height: 16px;
        line-height: 16px;
        font-weight: 400;
        font-size: 11px;
        padding: 0 5px;
      }
    }

    .h5 {
      font-size: 13px;
      line-height: 1.2em;
    }

    .h6 {
      font-size: 11px;
      line-height: 1.2em;
    }
  }
}
